import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Button, Stack } from '@mui/material';

export default function ExternalUserConfirmation(props) {
  const isExt = props.isExt ? props.isExt : false;

  const { t } = useTranslation();
  const { saveAndRedirect, addPhoneNumberExt } = props;

  const isLargeScreen = useMediaQuery('(min-width: 1200px)'); // 1200px = lg

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={2}>
      {!isLargeScreen ? (
        <Grid item xs={12}>
          <img
            src="/images/logo_zippedi.png"
            alt="LOGO"
            width="100%"
            height="auto"
          />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Typography variant="h4" component="h4" gutterBottom align="center">
            {t('heimdal_app.Login.2-factor_account_authentication')}
          </Typography>
        </Grid>
      )}

      <Grid item xs={12}>
        {isExt ? (
          <Typography align="center">
            {t('heimdal_app.Login.why_add_mfa')}
          </Typography>
        ) : (
          <Typography align="center">
            {t('heimdal_app.Login.add_phone_question')}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12}>
        <Stack
          direction="row"
          spacing={4}
          justifyContent="space-around"
          alignItems="center">
          <Button onClick={saveAndRedirect} variant="outlined">
            {t('heimdal_app.Login.dont_ask_again')}
          </Button>
          <Button onClick={addPhoneNumberExt} variant="contained">
            {t('heimdal_app.Login.protect_my_account')}
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
}
