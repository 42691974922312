import { createReducer } from './utils';

import {
  GET_PROFILE_FAILURE,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  REVOKE_MFA_FAILURE,
  REVOKE_MFA_REQUEST,
  REVOKE_MFA_SUCCESS,
  PATCH_PROFILE_FAILURE,
  PATCH_PROFILE_REQUEST,
  PATCH_PROFILE_SUCCESS,
} from './constants';

const defaultState = {
  loading: true,
  errorMessage: null,
  userProfile: null,
};

// reducer

export const reducer = createReducer(defaultState, {
  [GET_PROFILE_REQUEST]: handleGetProfileRequest,
  [GET_PROFILE_SUCCESS]: handleGetProfileSuccess,
  [GET_PROFILE_FAILURE]: handleGetProfileFailure,
  [REVOKE_MFA_REQUEST]: handleRevokeMFARequest,
  [REVOKE_MFA_SUCCESS]: handleRevokeMFASuccess,
  [REVOKE_MFA_FAILURE]: handleRevokeMFAFailure,
  [PATCH_PROFILE_REQUEST]: handlePatchProfileRequest,
  [PATCH_PROFILE_SUCCESS]: handlePatchProfileSuccess,
  [PATCH_PROFILE_FAILURE]: handlePatchProfileFailure,
});

function handleGetProfileRequest(state) {
  return {
    ...state,
    loading: true,
    errorMessage: null,
  };
}

function handleGetProfileSuccess(state, { payload: { userProfile } }) {
  return {
    ...state,
    userProfile,
    loading: false,
    errorMessage: null,
  };
}

function handleGetProfileFailure(state, { payload: { error } }) {
  return {
    ...state,
    loading: false,
    errorMessage: error,
    userProfile: null,
  };
}

function handleRevokeMFARequest(state) {
  return {
    ...state,
    loading: true,
    errorMessage: null,
  };
}

function handleRevokeMFASuccess(state) {
  return {
    ...state,
    loading: false,
    errorMessage: null,
  };
}

function handleRevokeMFAFailure(state, { payload: { error } }) {
  return {
    ...state,
    loading: false,
    errorMessage: error,
  };
}

function handlePatchProfileRequest(state) {
  return {
    ...state,
    loading: true,
    errorMessage: null,
  };
}

function handlePatchProfileSuccess(state, { payload: { userProfile } }) {
  return {
    ...state,
    userProfile,
    loading: false,
    errorMessage: null,
  };
}

function handlePatchProfileFailure(state, { payload: { error } }) {
  return {
    ...state,
    loading: false,
    errorMessage: error,
    userProfile: null,
  };
}

// actions

export function getProfile() {
  return async (dispatch, getState, { services: { authSource } }) => {
    dispatch({ type: GET_PROFILE_REQUEST });
    try {
      const userProfile = await authSource.getProfile();
      dispatch({
        type: GET_PROFILE_SUCCESS,
        payload: { userProfile },
      });
    } catch (error) {
      dispatch({
        type: GET_PROFILE_FAILURE,
        payload: { error },
      });
    }
  };
}

export function revokeMFA(body) {
  return async (dispatch, getState, { services: { authSource } }) => {
    dispatch({ type: REVOKE_MFA_REQUEST });
    try {
      await authSource.revokeMFA(body);
      dispatch({
        type: REVOKE_MFA_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: REVOKE_MFA_FAILURE,
        payload: { error },
      });
    }
  };
}

export function patchProfile(body) {
  return async (dispatch, getState, { services: { authSource } }) => {
    dispatch({ type: PATCH_PROFILE_REQUEST });
    try {
      const userProfile = await authSource.updateProfile(body);
      dispatch({
        type: PATCH_PROFILE_SUCCESS,
        payload: { userProfile },
      });
    } catch (error) {
      dispatch({
        type: PATCH_PROFILE_FAILURE,
        payload: { error },
      });
    }
  };
}
