import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Profile(props) {
  const navigate = useNavigate();
  const redirectUrl = new URLSearchParams(window.location.search).get(
    'redirect',
  );
  const tenant = new URLSearchParams(window.location.search).get('tenant');

  useEffect(() => {
    if (redirectUrl !== null) {
      localStorage.setItem('redirect', redirectUrl);
    }

    if (tenant === process.env.REACT_APP_EXTERNAL_USERS_TENANT_ID) {
      navigate('/profile/ext');
    } else if (tenant === process.env.REACT_APP_INTERNAL_USERS_TENANT_ID) {
      navigate('/profile/int');
    } else {
      window.location.href = `${process.env.REACT_APP_ALT_URL}/profile?tenant=${tenant}&redirect=${redirectUrl}`;
    }
  }, []);

  return null;
}
