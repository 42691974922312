import { Box, useTheme } from '@mui/material';

export default function StyledModalBox(props) {
  const { children, inputBackground = 'white', Inputcolor } = props;
  const theme = useTheme();
  const lightBackground = inputBackground
    ? inputBackground
    : Object.values(theme.palette[inputBackground])[0];

  const style = {
    position: 'absolute',
    maxWidth: { xs: '90%', md: '40%' },
    background:
      theme.palette.type === 'light'
        ? lightBackground
        : theme.palette.paper.main,
    color: Inputcolor
      ? Inputcolor
      : theme.palette.type === 'light'
      ? theme.palette.primary.main + 'D9'
      : 'rgba(255, 255, 255, 0.87)',
    borderRadius: '0.5rem',
    boxShadow: 24,
    padding: '2rem',
    maxHeight: '80vh',
    overflowY: 'auto',
    margin: '2rem',
  };

  return (
    <Box {...props} sx={style}>
      {children}
    </Box>
  );
}
