import { createReducer } from './utils';
import Cookie from 'js-cookie';

import {
  SIGN_IN_FAILURE,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  LOG_OUT_REQUEST,
  LOG_OUT_SUCCESS,
  LOG_OUT_FAILURE,
  CSRF_TOKEN_REQUEST,
  CSRF_TOKEN_SUCCESS,
  CSRF_TOKEN_FAILURE,
  SESSION_COOKIE_FAILURE,
  SESSION_COOKIE_REQUEST,
  SESSION_COOKIE_SUCCESS,
  NIST_CHECK_PASSWORD_REQUEST,
  NIST_CHECK_PASSWORD_SUCCESS,
  NIST_CHECK_PASSWORD_FAILURE,
} from './constants';

const defaultState = {
  loading: true,
  loadingLogOut: false,
  loggedIn: false,
  errorMessage: null,
  userInfo: {},
  session: false,
  validatePasswordResponse: null,
  loadingNIST: false,
};

// reducer

export const reducer = createReducer(defaultState, {
  [SIGN_IN_REQUEST]: handleSignInRequest,
  [SIGN_IN_SUCCESS]: handleSignInSuccess,
  [SIGN_IN_FAILURE]: handleSignInFailure,
  [LOG_OUT_REQUEST]: handleLogOutRequest,
  [LOG_OUT_SUCCESS]: handleLogOutSuccess,
  [LOG_OUT_FAILURE]: handleLogOutFailure,
  [CSRF_TOKEN_REQUEST]: handleCSRFTokenRequest,
  [CSRF_TOKEN_SUCCESS]: handleCSRFTokenSuccess,
  [CSRF_TOKEN_FAILURE]: handleCSRFTokenFailure,
  [SESSION_COOKIE_REQUEST]: handleSessionCookieRequest,
  [SESSION_COOKIE_SUCCESS]: handleSessionCookieSuccess,
  [SESSION_COOKIE_FAILURE]: handleSessionCookieFailure,
  [NIST_CHECK_PASSWORD_REQUEST]: handleNISTCheckPasswordRequest,
  [NIST_CHECK_PASSWORD_SUCCESS]: handleNISTCheckPasswordSuccess,
  [NIST_CHECK_PASSWORD_FAILURE]: handleNISTCheckPasswordFailure,
});

function handleSignInRequest(state) {
  return {
    ...state,
    loading: true,
    errorMessage: null,
  };
}

function handleSignInSuccess(state, { payload: { userInfo } }) {
  return {
    ...state,
    userInfo,
    loading: false,
    loggedIn: true,
    errorMessage: null,
  };
}

function handleSignInFailure(state, { payload: { error } }) {
  return {
    ...state,
    loading: false,
    loggedIn: false,
    errorMessage: error,
    userInfo: {},
  };
}

function handleLogOutRequest(state) {
  return {
    ...state,
    loadingLogOut: true,
  };
}

function handleLogOutSuccess(state) {
  return {
    ...state,
    loading: false,
    loadingLogOut: false,
    loggedIn: false,
    errorMessage: null,
    userInfo: {},
  };
}

function handleLogOutFailure(state, { payload: { error } }) {
  return {
    ...state,
    loadingLogOut: false,
    errorMessage: error,
  };
}

function handleCSRFTokenRequest(state) {
  return {
    ...state,
    loading: true,
    errorMessage: null,
  };
}

function handleCSRFTokenSuccess(state) {
  return {
    ...state,
    loading: false,
    errorMessage: null,
  };
}

function handleCSRFTokenFailure(state, { payload: { error } }) {
  return {
    ...state,
    loading: false,
    errorMessage: error,
  };
}

function handleSessionCookieRequest(state) {
  return {
    ...state,
    loading: true,
    errorMessage: null,
    session: false,
  };
}

function handleSessionCookieSuccess(state) {
  return {
    ...state,
    loading: false,
    errorMessage: null,
    session: true,
  };
}

function handleSessionCookieFailure(state, { payload: { error } }) {
  return {
    ...state,
    loading: false,
    errorMessage: error,
    session: false,
  };
}

function handleNISTCheckPasswordRequest(state) {
  return {
    ...state,
    loadingNIST: true,
    errorMessage: null,
  };
}

function handleNISTCheckPasswordSuccess(
  state,
  { payload: { validatePasswordResponse } },
) {
  return {
    ...state,
    loadingNIST: false,
    errorMessage: null,
    validatePasswordResponse,
  };
}

function handleNISTCheckPasswordFailure(state, { payload: { error } }) {
  return {
    ...state,
    loadingNIST: false,
    errorMessage: error,
  };
}

// actions

export function checkSession() {
  return async (dispatch, getState, { services: { authSource } }) => {
    dispatch({ type: SIGN_IN_REQUEST });
    try {
      const userInfo = await authSource.checkAuthenticated();
      dispatch({
        type: SIGN_IN_SUCCESS,
        payload: { userInfo },
      });
    } catch (error) {
      dispatch({
        type: SIGN_IN_FAILURE,
        payload: { error },
      });
    }
  };
}

export function logOut() {
  return async (dispatch, getState, { services: { authSource } }) => {
    dispatch({ type: LOG_OUT_REQUEST });
    try {
      await authSource.logOut();
      dispatch({
        type: LOG_OUT_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: LOG_OUT_FAILURE,
        payload: { error },
      });
    }
  };
}

export function getCSRFToken() {
  return async (dispatch, getState, { services: { authSource } }) => {
    dispatch({ type: CSRF_TOKEN_REQUEST });
    try {
      await authSource.getCSRFToken();
      dispatch({
        type: CSRF_TOKEN_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: CSRF_TOKEN_FAILURE,
        payload: { error },
      });
    }
  };
}

export function getSessionCookie(body) {
  return async (dispatch, getState, { services: { authSource } }) => {
    dispatch({ type: SESSION_COOKIE_REQUEST });
    try {
      await authSource.getSessionCookie(body, Cookie.get('_csrf_token'));
      dispatch({
        type: SESSION_COOKIE_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: SESSION_COOKIE_FAILURE,
        payload: { error },
      });
    }
  };
}

export function nistCheckPassword(body) {
  return async (dispatch, getState, { services: { authSource } }) => {
    dispatch({ type: NIST_CHECK_PASSWORD_REQUEST });
    try {
      const validatePasswordResponse = await authSource.nistCheckPassword(body);
      dispatch({
        type: NIST_CHECK_PASSWORD_SUCCESS,
        payload: { validatePasswordResponse },
      });
    } catch (error) {
      dispatch({
        type: NIST_CHECK_PASSWORD_FAILURE,
        payload: { error },
      });
    }
  };
}
