import { connect } from 'react-redux';
import Profile from '../../../components/profile/Profile';
import { getProfile } from 'core/slices/profile';
import { userProfile, isLoadingProfile, isLoadingValidatePassword } from 'core/selectors';

export default connect(
  state => ({
    userProfile: userProfile(state),
    isLoadingProfile: isLoadingProfile(state),
    isLoadingValidatePassword: isLoadingValidatePassword(state),
  }),
  { getProfile },
)(Profile);
