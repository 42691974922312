import React, { Component } from 'react';
import { Menu, MenuItem, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { withTranslation } from 'react-i18next';

//Icons
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';

const StyledMenu = styled(props => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor:
      theme.palette.type === 'light' ? '#1a242d' : theme.palette.paper.main,
    borderRadius: 3,
    marginTop: theme.spacing(2),
    minWidth: 300,
    color: theme.palette.mode === 'light' ? 'white' : 'black',
  },
}));

const styles = {
  text: {
    fontSize: 14,
    marginLeft: 12,
  },
};

class MyMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      views: [this.props.t('General.edit_profile')],
      icons: ['pencil'],
    };
  }
  handleCloseMenu = () => {
    this.props.setOpen(false);
  };
  render() {
    const { open, anchorEl } = this.props;
    const { views, icons } = this.state;

    return (
      <StyledMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={this.handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        {views.map((view, index) => (
          <MenuItem onClick={this.handleCloseMenu}>
            <IconComponent iconName={icons[index]} className={'icon'} />
            <Typography style={styles.text}>{view}</Typography>
          </MenuItem>
        ))}
      </StyledMenu>
    );
  }
}

export default withTranslation()(MyMenu);
