export const CREDENTIALS_REQUEST = 'CREDENTIALS_REQUEST';
export const CREDENTIALS_AVAILABLE = 'CREDENTIALS_AVAILABLE';
export const CREDENTIALS_UNAVAILABLE = 'CREDENTIALS_UNAVAILABLE';
export const CREDENTIALS_STORE = 'CREDENTIALS_STORE';
export const CREDENTIALS_CLEAR = 'CREDENTIALS_CLEAR';

// Auth
export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';
export const LOG_OUT_REQUEST = 'LOG_OUT_REQUEST';
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS';
export const LOG_OUT_FAILURE = 'LOG_OUT_FAILURE';
export const CSRF_TOKEN_REQUEST = 'CSRF_TOKEN_REQUEST';
export const CSRF_TOKEN_SUCCESS = 'CSRF_TOKEN_SUCCESS';
export const CSRF_TOKEN_FAILURE = 'CSRF_TOKEN_FAILURE';
export const SESSION_COOKIE_REQUEST = 'SESSION_COOKIE_REQUEST';
export const SESSION_COOKIE_FAILURE = 'SESSION_COOKIE_FAILURE';
export const SESSION_COOKIE_SUCCESS = 'SESSION_COOKIE_SUCCESS';
export const NIST_CHECK_PASSWORD_REQUEST = 'NIST_CHECK_PASSWORD_REQUEST';
export const NIST_CHECK_PASSWORD_FAILURE = 'NIST_CHECK_PASSWORD_FAILURE';
export const NIST_CHECK_PASSWORD_SUCCESS = 'NIST_CHECK_PASSWORD_SUCCESS';

// Profile
export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAILURE = 'GET_PROFILE_FAILURE';
export const REVOKE_MFA_REQUEST = 'REVOKE_MFA_REQUEST';
export const REVOKE_MFA_SUCCESS = 'REVOKE_MFA_SUCCESS';
export const REVOKE_MFA_FAILURE = 'REVOKE_MFA_FAILURE';
export const PATCH_PROFILE_REQUEST = 'PATCH_PROFILE_REQUEST';
export const PATCH_PROFILE_SUCCESS = 'PATCH_PROFILE_SUCCESS';
export const PATCH_PROFILE_FAILURE = 'PATCH_PROFILE_FAILURE';

// Zippedi
export const support_phone_number = 56229381339;

// URLS
export const whatsAppUrl = 'https://wa.me/';
