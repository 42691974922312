import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  applyActionCode,
  checkActionCode,
  confirmPasswordReset,
  fetchSignInMethodsForEmail,
  getAuth,
  sendPasswordResetEmail,
  verifyPasswordResetCode,
} from 'firebase/auth';
import {
  Grid,
  Link,
  Typography,
  Button,
  InputAdornment,
  IconButton,
  OutlinedInput,
  FormControl,
  InputLabel,
  TextField,
  Alert,
} from '@mui/material';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';

import { support_phone_number, whatsAppUrl } from 'core/slices/constants';

import Copyright from '../utils/Copyright';

export default function Recover(props) {
  const { t } = useTranslation();
  const [language, setLanguage] = useState('en');
  const [error, setError] = useState(false);
  const [value, setAlert] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);
  const [email, setMail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  let search = useLocation();
  const navigate = useNavigate();
  let query = new URLSearchParams(search.search);
  let mode = query.get('mode');
  let tenant = query.get('tenantId');
  let code = query.get('oobCode');

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleClickShowPasswordConfirmation = () =>
    setShowPasswordConfirmation(show => !show);

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleMouseDownPasswordConfirmation = event => {
    event.preventDefault();
  };

  const handleChangeEmail = event => {
    setMail(event.target.value);
  };
  const handleChangePassword = event => {
    setPassword(event.target.value);
  };
  const handleChangePasswordConfirmation = event => {
    setPasswordConfirmation(event.target.value);
  };

  const isLargeScreen = useMediaQuery('(min-width: 1200px)'); // 1200px = lg

  const fullWhatsAppUrl = `${whatsAppUrl}${support_phone_number}?text=${t(
    'heimdal_app.Login.help_initial_text',
  )}`;

  useEffect(() => {
    let languages = ['en', 'es', 'de'];
    let userLanguage = navigator.language || navigator.userLanguage || 'en-US';
    userLanguage = userLanguage.split('-')[0];
    userLanguage = languages.includes(userLanguage) ? userLanguage : 'en';

    setLanguage(userLanguage);
  }, []);

  useEffect(() => {
    if (props.hasValidatePasswordResponse) {
      const { message, isValidPassword } = props.hasValidatePasswordResponse;
      if (!isValidPassword) {
        alert(t('heimdal_app.PassRecovery.' + message));
        setError(true);
      } else {
        const auth = getAuth();
        auth.tenantId = tenant;
        confirmPasswordReset(auth, code, password)
          .then(response => {
            setError(false);
            alert(t('heimdal_app.PassRecovery.password_changed'));
          })
          .catch(error => {
            console.error(error);
            setError(true);
            if (error.code === 'auth/weak-password') {
              alert(t('heimdal_app.PassRecovery.weak_password'));
            } else {
              alert(t('heimdal_app.Login.try_again_later'));
            }
          });
      }
    }
  }, [props.hasValidatePasswordResponse]);

  const revokeMFA = () => {
    const auth = getAuth();
    auth.tenantId = tenant;
    var obfuscatedPhoneNumber;
    checkActionCode(auth, code)
      .then(function (info) {
        var multiFactorInfo = info['data']['multiFactorInfo'];
        obfuscatedPhoneNumber = multiFactorInfo['phoneNumber'];
        return applyActionCode(auth, code);
      })
      .then(function () {
        alert(
          t('heimdal_app.PassRecovery.revoke_mfa_msg_1') +
            obfuscatedPhoneNumber +
            t('heimdal_app.PassRecovery.revoke_mfa_msg_2'),
        );
        window.location.replace('/');
      })
      .catch(function (error) {
        console.log('error revoke: ', error);
      });
  };

  const handlePassword = event => {
    event.preventDefault();
    const auth = getAuth();
    auth.tenantId = tenant;

    if (password === passwordConfirmation) {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const googleOobKey = urlParams.get('oobCode');

        verifyPasswordResetCode(auth, googleOobKey)
          .then(info => {
            props.nistCheckPassword({
              info,
              password,
            });
          })
          .catch(error => {
            alert(t('heimdal_app.PassRecovery.token_expired'));
            setError(true);
          });
      } catch (error) {
        console.error('error: ', error);
      }
    } else {
      alert(t('heimdal_app.PassRecovery.password_match'));
      setError(true);
    }
  };

  const handleSubmit = event => {
    event.preventDefault();
    const auth = getAuth();
    auth.tenantId = tenant;

    fetchSignInMethodsForEmail(auth, email)
      .then(response => {
        if (response.length === 0) {
          alert(t('heimdal_app.PassRecovery.email_not_registered'));
        } else {
          if (response.includes('password')) {
            auth.languageCode = language;
            sendPasswordResetEmail(auth, email)
              .then(setAlert(!value))
              .catch(error => {
                console.log(error);
              });
          } else {
            alert(t('heimdal_app.PassRecovery.email_not_registered_provider'));
          }
        }
      })
      .catch(error => {
        console.log('error: ', error);
      });
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      spacing={isLargeScreen ? '-2em' : 0}
      height={'100vh'}>
      <Grid item md={0} lg={6} xl={5} alignContent={'center'}>
        {isLargeScreen ? (
          <img
            src="/images/logo_zippedi.png"
            alt="LOGO"
            width="80%"
            height="auto"
            style={{ marginLeft: '2em' }}
          />
        ) : null}
      </Grid>
      <Grid item xs={10} md={8} lg={6} xl={4}>
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          {!isLargeScreen ? (
            <Grid item xs={12}>
              <img
                src="/images/logo_zippedi.png"
                alt="LOGO"
                width="100%"
                height="auto"
              />
            </Grid>
          ) : mode === 'resetPassword' ? (
            <Typography variant="h5" component="h1" gutterBottom align="center">
              {t('heimdal_app.PassRecovery.new_password_description')}
            </Typography>
          ) : mode === 'revertSecondFactorAddition' ? (
            <Typography variant="h5" component="h1" gutterBottom align="center">
              {t('heimdal_app.PassRecovery.disable_2FA_description')}
            </Typography>
          ) : (
            <Typography variant="h5" component="h1" gutterBottom align="center">
              {t('heimdal_app.PassRecovery.email_description')}
            </Typography>
          )}

          {mode === 'resetPassword' ? (
            <>
              <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="outlined-adornment-password">
                    {t('heimdal_app.PassRecovery.new_password')}
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    required
                    error={error}
                    fullWidth
                    name="password"
                    value={password}
                    label={t('heimdal_app.PassRecovery.new_password')}
                    onChange={handleChangePassword}
                    autoFocus
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end">
                          {showPassword ? (
                            <IconComponent
                              iconName={'eye-off-outline'}
                              className={'icon'}
                            />
                          ) : (
                            <IconComponent
                              iconName={'eye-outline'}
                              className={'icon'}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="outlined-adornment-password">
                    {t('heimdal_app.PassRecovery.confirm_password')}
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPasswordConfirmation ? 'text' : 'password'}
                    required
                    error={error}
                    fullWidth
                    name="password"
                    value={passwordConfirmation}
                    label={t('heimdal_app.PassRecovery.confirm_password')}
                    onChange={handleChangePasswordConfirmation}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPasswordConfirmation}
                          onMouseDown={handleMouseDownPasswordConfirmation}
                          edge="end">
                          {showPasswordConfirmation ? (
                            <IconComponent
                              iconName={'eye-off-outline'}
                              className={'icon'}
                            />
                          ) : (
                            <IconComponent
                              iconName={'eye-outline'}
                              className={'icon'}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" fullWidth onClick={handlePassword}>
                  {t('heimdal_app.PassRecovery.confirm')}
                </Button>
              </Grid>
            </>
          ) : mode === 'revertSecondFactorAddition' ? (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              onClick={revokeMFA}
              sx={{ mt: 3, mb: 2 }}>
              {t('heimdal_app.PassRecovery.disable_2FA')}
            </Button>
          ) : (
            <>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  value={email}
                  label={t('heimdal_app.Login.email')}
                  variant="outlined"
                  fullWidth
                  type="email"
                  name="email"
                  required
                  autoFocus
                  onChange={handleChangeEmail}
                />
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" fullWidth onClick={handleSubmit}>
                  {t('heimdal_app.PassRecovery.confirm')}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  sx={{ mt: 3, mb: 2, textAlign: 'center' }}
                  startIcon={
                    <IconComponent
                      iconName={'arrow-back-outline'}
                      className={'icon'}
                    />
                  }
                  onClick={() => {
                    navigate(-1);
                  }}>
                  {t('heimdal_app.PassRecovery.go_back')}
                </Button>
              </Grid>
              <Grid item xs={12}>
                {value ? (
                  <Alert severity="success">
                    {t('heimdal_app.PassRecovery.check_email')}
                  </Alert>
                ) : (
                  <></>
                )}
              </Grid>
            </>
          )}
        </Grid>
        <Grid item xs={12} marginTop={'2em'}>
          <Copyright />
        </Grid>
        <Grid item xs={12} marginTop={'1em'} alignItems={'center'}>
          <Typography align="center" variant="body2">
            <Link href={fullWhatsAppUrl}>
              {t('heimdal_app.Login.need_help')}
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

/*



*/
