import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import StyledModalBox from '../tools/StyledModalBox';

export default function MissingCredentialsModal(props) {
  const { logOut, userProfile } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (userProfile) {
      setOpen(false);
    }
  }, [userProfile]);

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}>
        <StyledModalBox>
          <Grid container justifyContent="flex-end">
            <Grid item xs={12} md={12}>
              <Grid container>
                <Grid item xs={11}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    textAlign={'left'}>
                    {t(
                      'heimdal_app.Profile.invalid_credentials',
                      'Invalid credentials',
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} paddingY={2}>
              <Typography
                id="modal-modal-description"
                variant="body2"
                textAlign={'justify'}>
                {t(
                  'heimdal_app.Profile.invalid_credentials_description',
                  'Failed to retrieve user information. Please log in again to refresh credentials',
                )}
              </Typography>
            </Grid>
            <Grid container justifyContent="right" display={'flex'}>
              <Grid item xs={3}>
                <Typography
                  variant="subtitle2"
                  textAlign={'right'}
                  color={theme.palette.secondary.main}
                  style={{ cursor: 'pointer', userSelect: 'none' }}
                  onClick={() => logOut()}>
                  {t('heimdal_app.Menu.log_out')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </StyledModalBox>
      </Modal>
    </div>
  );
}
