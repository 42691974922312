import { connect } from 'react-redux';
import MissingCredentialsModal from '../../../components/profile/MissingCredentialsModal';
import { logOut } from 'core/slices/session';
import { userProfile } from 'core/selectors';

export default connect(
  state => ({
    userProfile: userProfile(state),
  }),
  { logOut },
)(MissingCredentialsModal);
