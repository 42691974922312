export function isLoadingSession({ session: { loading } }) {
  return loading;
}

export function userInfo({ session: { userInfo } }) {
  return userInfo;
}

export function hasSession({ session: { loggedIn } }) {
  return loggedIn;
}

export function hasSessionCookie({ session: { session } }) {
  return session;
}

export function hasSessionError({ session: { errorMessage } }) {
  return errorMessage;
}

export function hasValidatePasswordResponse({
  session: { validatePasswordResponse },
}) {
  return validatePasswordResponse;
}

export function isLoadingValidatePassword({
  session: { loadingNIST },
}) {
  return loadingNIST;
}

export function userProfile({ profile: { userProfile } }) {
  return userProfile;
}

export function isLoadingProfile({ profile: { loading } }) {
  return loading;
}
