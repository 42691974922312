export default function DataSourceService({ baseUrl, http }) {
  const PATH_GENERIC_INFO = '/info';

  return {
    getGenericInfo,
  };

  function getGenericInfo({ info }) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_GENERIC_INFO}`,
      body: {
        info,
      },
    });
  }
}
