import { connect } from 'react-redux';
import Providers from '../../../components/profile/Providers';
import { userProfile, isLoadingProfile } from 'core/selectors';

export default connect(
  state => ({
    userProfile: userProfile(state),
    isLoadingProfile: isLoadingProfile(state),
  }),
  {},
)(Providers);
