import { connect } from 'react-redux';
import Recover from '../../../components/login/Recover';
import { nistCheckPassword } from 'core/slices/session';
import { hasValidatePasswordResponse } from 'core/selectors';

export default connect(
  state => ({
    hasValidatePasswordResponse: hasValidatePasswordResponse(state),
  }),
  { nistCheckPassword },
)(Recover);
