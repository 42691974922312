import { connect } from 'react-redux';
import Welcome from '../../../components/login/Welcome';
import { getCSRFToken, getSessionCookie } from 'core/slices/session';
import {
  isLoadingSession,
  hasSessionCookie,
  hasSessionError,
} from 'core/selectors';

export default connect(
  state => ({
    isLoadingSession: isLoadingSession(state),
    hasSessionCookie: hasSessionCookie(state),
    hasSessionError: hasSessionError(state),
  }),
  { getCSRFToken, getSessionCookie },
)(Welcome);
