import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Grid,
  Typography,
  FormControl,
  FormHelperText,
  InputAdornment,
  Skeleton,
  IconButton,
  Divider,
  useTheme,
  Button,
  Tooltip,
} from '@mui/material';
import {
  getAuth,
  signInWithEmailAndPassword,
  getMultiFactorResolver,
  RecaptchaVerifier,
  GoogleAuthProvider,
  signInWithRedirect,
  EmailAuthProvider,
  linkWithCredential,
  signInWithPopup,
} from 'firebase/auth';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';
import { generatePassword } from '@zippeditoolsjs/security';

import StyledOutlinedInput from '../tools/StyledOutlinedInput';
import StyledTelInput from '../tools/StyledTelInput';

import MFAVerify from './MFAVerify';
import ConfirmationModal from './ConfirmationModal';

export default function ProfileForm(props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery('(min-width: 992px)'); // 992px = md

  const styles = {
    typography: {
      secondary: {
        color:
          theme.palette.type === 'light'
            ? 'rgba(0, 0, 0, 0.6)'
            : 'rgba(255, 255, 255, 0.6)',
      },
    },
    iconComponent: {
      color:
        theme.palette.type === 'dark' ? 'white' : theme.palette.primary.main,
    },
    iconButton: {
      ':hover': {
        backgroundColor:
          theme.palette.type === 'dark'
            ? `${theme.palette.primary.main}33`
            : `{theme.palette.grey[400]}33`,
      },
    },
  };

  const {
    userProfile,
    isLoading,
    setIsLoading,
    patchProfile,
    submit,
    setSubmit,
    nistCheckPassword,
    hasValidatePasswordResponse,
    canCreateAccount,
    resolver,
    setResolver,
    open,
    setOpen,
    setErrorNew,
    setErrorNewMsg,
    errorNew,
    errorNewMsg,
    newPassword,
    setNewPassword,
    confirmNewPassword,
    setConfirmNewPassword,
    errorPasswordConfirmation,
    setErrorPasswordConfirmation,
  } = props;
  const [name, setName] = useState('');
  const [lastNames, setLastNames] = useState('');
  const [contact, setContact] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);
  const [errorCurrent, setErrorCurrent] = useState(false);
  const [language, setLanguage] = useState('en');
  const [showCurrentPasswordField, setShowCurrentPasswordField] =
    useState(false);
  const [mode, setMode] = useState('update');
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const setUpRecaptcha = () => {
    const auth = getAuth();
    auth.tenantId = userProfile.tenantId;
    auth.languageCode = language;
    window.recaptchaVerifier = new RecaptchaVerifier(
      'recaptcha',
      { size: 'invisible' },
      auth,
    );
  };

  const handleChangePhone = value => {
    setContact(value);
  };

  const handleClickShowCurrentPassword = () =>
    setShowCurrentPassword(show => !show);

  const handleClickShowNewPassword = () => setShowNewPassword(show => !show);

  const handleClickShowPasswordConfirmation = () =>
    setShowPasswordConfirmation(show => !show);

  const handleMouseDownPasswordCurrent = event => event.preventDefault();

  const handleMouseDownPasswordNew = event => event.preventDefault();

  const handleMouseDownPasswordConfirmation = event => event.preventDefault();

  const handleUpdateProfile = async () => {
    setErrorCurrent(false);
    setErrorNew(false);
    setErrorPasswordConfirmation(false);
    setErrorNewMsg('');
    if (newPassword !== confirmNewPassword) {
      setSubmit(false);
      setErrorPasswordConfirmation(true);
    } else {
      if (newPassword === '' && currentPassword === '') {
        await patchProfile({
          uid: userProfile.uid,
          tenantId: userProfile.tenantId,
          name: name,
          lastNames: lastNames,
          phoneNumber: contact,
        });
        setSubmit(false);
      } else {
        sessionStorage.setItem('newPassword', newPassword);
        await nistCheckPassword({
          password: newPassword,
          info: userProfile.email,
          tenantId: userProfile.tenantId,
        });
        setSubmit(false);
      }
    }
  };

  const handleGenerateNewPassword = () => {
    const generated = generatePassword();
    setShowNewPassword(true);
    setShowPasswordConfirmation(true);
    setNewPassword(generated);
    setConfirmNewPassword(generated);
  };

  const handleUpdateProfileWithPassword = async () => {
    await patchProfile({
      uid: userProfile.uid,
      tenantId: userProfile.tenantId,
      name: name,
      lastNames: lastNames,
      phoneNumber: contact,
      password: newPassword,
    });
    window.location.reload();
  };

  const handleCreateEmailAccount = async () => {
    const auth = getAuth();
    auth.tenantId = userProfile.tenantId;
    setIsLoading(true);
    const new_password = sessionStorage.getItem('newPassword');
    const credential = EmailAuthProvider.credential(
      userProfile.email,
      new_password,
    );

    await patchProfile({
      uid: userProfile.uid,
      tenantId: userProfile.tenantId,
      name: name,
      lastNames: lastNames,
      phoneNumber: contact,
    });

    linkWithCredential(auth.currentUser, credential)
      .then(() => {
        setIsLoading(false);
      })
      .then(() => {
        sessionStorage.removeItem('newPassword');
        window.location.reload();
      })
      .catch(error => {
        console.log('Account linking error', error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (hasValidatePasswordResponse) {
      if (hasValidatePasswordResponse.isValidPassword) {
        if (!showCurrentPasswordField) {
          const auth = getAuth();
          const provider = new GoogleAuthProvider();
          auth.tenantId = userProfile.tenantId;
          auth.languageCode = language;
          sessionStorage.setItem('action', 'createEmailAccount');
          if (window.location.hostname !== 'localhost') {
            signInWithRedirect(auth, provider);
          } else {
            signInWithPopup(auth, provider)
              .then(result => {
                if (result) {
                  handleCreateEmailAccount();
                }
              })
              .catch(error => {
                if (error.code === 'auth/multi-factor-auth-required') {
                  setResolver(getMultiFactorResolver(auth, error));
                  props.setOpenOnProfile(true);
                }
                console.log(error);
              });
          }
        } else {
          const auth = getAuth();
          auth.tenantId = userProfile.tenantId;
          setIsLoading(true);
          signInWithEmailAndPassword(auth, userProfile.email, currentPassword)
            .then(() => {
              handleUpdateProfileWithPassword();
            })
            .catch(error => {
              if (error.code === 'auth/wrong-password') {
                setErrorCurrent(true);
                setIsLoading(false);
              } else if (error.code === 'auth/multi-factor-auth-required') {
                setResolver(getMultiFactorResolver(auth, error));
                setOpen(true);
              }
            });
        }
      } else {
        setErrorNew(true);
        setErrorNewMsg(hasValidatePasswordResponse.message);
        setIsLoading(false);
      }
    }
  }, [hasValidatePasswordResponse]);

  useEffect(() => {
    if (!isLoading) {
      setName(userProfile.displayName.split(' ')[0]);
      setLastNames(
        userProfile.displayName.substring(
          userProfile.displayName.split(' ')[0].length + 1,
        ),
      );
      setContact(userProfile.phoneNumber);
      setUpRecaptcha();
      const providers = userProfile.providerUserInfo.map(provider => {
        return provider.providerId;
      });
      if (providers.includes('password')) {
        setShowCurrentPasswordField(true);
      }
    }
  }, [userProfile]);

  useEffect(() => {
    if (submit) {
      handleUpdateProfile();
    }
  }, [submit]);

  useEffect(() => {
    let languages = ['en', 'es', 'de'];
    let userLanguage = navigator.language || navigator.userLanguage || 'en-US';
    userLanguage = userLanguage.split('-')[0];
    userLanguage = languages.includes(userLanguage) ? userLanguage : 'en';

    setLanguage(userLanguage);
  }, []);

  useEffect(() => {
    if (canCreateAccount) {
      handleCreateEmailAccount();
    }
  }, [canCreateAccount]);

  useEffect(() => {
    if (resolver) {
      if (sessionStorage.getItem('action') === 'createEmailAccount') {
        sessionStorage.removeItem('action');
        setMode('create');
      }
    }
  }, [resolver]);

  return (
    <>
      <Grid item xs={12} md={6} paddingY={2} paddingX={4}>
        <Grid item xs={12}>
          {isLoading ? (
            <Skeleton animation="wave" height={24} width="40%" />
          ) : (
            <Typography textAlign={'left'} variant="h6">
              {t('heimdal_app.Profile.user_information')}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} marginY={'1em'}>
          {isLoading ? (
            <Skeleton animation="wave" height={24} width="30%" />
          ) : (
            <Typography textAlign={'left'} variant="subtitle2">
              {t('heimdal_app.Login.email')}
            </Typography>
          )}
          {isLoading ? (
            <Skeleton animation="wave" height={40} width="100%" />
          ) : (
            <StyledOutlinedInput
              fullWidth
              size="small"
              variant="outlined"
              value={userProfile?.email ? userProfile.email : ''}
              disabled
            />
          )}
        </Grid>
        <Grid item xs={12} marginY={'1em'}>
          {isLoading ? (
            <Skeleton animation="wave" height={24} width="30%" />
          ) : (
            <Typography textAlign={'left'} variant="subtitle2">
              {t('heimdal_app.Profile.name')}
            </Typography>
          )}
          {isLoading ? (
            <Skeleton animation="wave" height={40} width="100%" />
          ) : (
            <StyledOutlinedInput
              fullWidth
              size="small"
              id="name"
              name="name"
              variant="outlined"
              value={name}
              onChange={e => {
                const value = e.target.value;
                if (value.match(/^[/^\S*$/]*$/)) {
                  setName(value);
                }
              }}
            />
          )}
        </Grid>
        <Grid item xs={12} marginY={'1em'}>
          {isLoading ? (
            <Skeleton animation="wave" height={24} width="40%" />
          ) : (
            <Typography textAlign={'left'} variant="subtitle2">
              {t('heimdal_app.Profile.last_names')}
            </Typography>
          )}
          {isLoading ? (
            <Skeleton animation="wave" height={40} width="100%" />
          ) : (
            <StyledOutlinedInput
              fullWidth
              size="small"
              id="lastNames"
              name="lastNames"
              variant="outlined"
              value={lastNames}
              onChange={e => setLastNames(e.target.value)}
            />
          )}
        </Grid>
        <Grid item xs={12} marginY={'1em'}>
          {isLoading ? (
            <Skeleton animation="wave" height={24} width="40%" />
          ) : (
            <Typography textAlign={'left'} variant="subtitle2">
              {t('heimdal_app.Profile.contact')}
            </Typography>
          )}
          {isLoading ? (
            <Skeleton animation="wave" height={40} width="100%" />
          ) : (
            <StyledTelInput
              fullWidth
              size="small"
              value={contact}
              defaultCountry="US"
              onChange={handleChangePhone}
              forceCallingCode
              focusOnSelectCountry
              MenuProps={{
                sx: {
                  '& .MuiPaper-root': {
                    backgroundColor:
                      theme.palette.type === 'light'
                        ? 'white'
                        : theme.palette.paper.main,
                    color: theme.palette.type === 'light' ? 'black' : 'white',
                  },
                  '.MuiTelInput-Typography-calling-code': {
                    color: theme.palette.type === 'light' ? 'black' : 'white',
                  },
                },
              }}
            />
          )}
        </Grid>
      </Grid>
      {isLargeScreen && (
        <Divider orientation="vertical" flexItem sx={{ mr: '-1px' }} />
      )}
      <Grid item xs={12} md={6} paddingY={2} paddingX={4}>
        <Grid item xs={12}>
          {isLoading ? (
            <Skeleton animation="wave" height={24} width="40%" />
          ) : (
            <Typography textAlign={'left'} variant="h6">
              {t('heimdal_app.Profile.password')}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} marginY={'1em'}>
          {isLoading ? (
            <Skeleton animation="wave" height={24} width="40%" />
          ) : showCurrentPasswordField ? (
            <Typography textAlign={'left'} variant="subtitle2">
              {t('heimdal_app.Profile.current_password')}
            </Typography>
          ) : null}
          {isLoading ? (
            <Skeleton animation="wave" height={40} width="100%" />
          ) : showCurrentPasswordField ? (
            <FormControl variant="outlined" fullWidth>
              <StyledOutlinedInput
                id="outlined-adornment-password"
                type={showCurrentPassword ? 'text' : 'password'}
                required
                error={errorCurrent}
                size={'small'}
                fullWidth
                name="password"
                value={currentPassword}
                onChange={e => setCurrentPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowCurrentPassword}
                      onMouseDown={handleMouseDownPasswordCurrent}
                      edge="end"
                      sx={styles.iconButton}>
                      {showCurrentPassword ? (
                        <IconComponent
                          iconName={'eye-off-outline'}
                          className={'icon'}
                          style={styles.iconComponent}
                        />
                      ) : (
                        <IconComponent
                          iconName={'eye-outline'}
                          className={'icon'}
                          style={styles.iconComponent}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText error={errorCurrent}>
                {errorCurrent ? t('heimdal_app.Login.invalid_password') : ''}
              </FormHelperText>
            </FormControl>
          ) : null}
        </Grid>
        <Grid item xs={12} marginY={'1em'}>
          {isLoading ? (
            <Skeleton animation="wave" height={24} width="40%" />
          ) : (
            <Typography textAlign={'left'} variant="subtitle2">
              {t('heimdal_app.PassRecovery.new_password')}
            </Typography>
          )}
          {isLoading ? (
            <Skeleton animation="wave" height={40} width="100%" />
          ) : (
            <>
              <FormControl variant="outlined" fullWidth>
                <StyledOutlinedInput
                  id="outlined-adornment-password"
                  type={showNewPassword ? 'text' : 'password'}
                  required
                  error={errorNew}
                  fullWidth
                  size={'small'}
                  name="password"
                  value={newPassword}
                  onChange={e => setNewPassword(e.target.value)}
                  placeholder={t('heimdal_app.Profile.password_placeholder')}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowNewPassword}
                        onMouseDown={handleMouseDownPasswordNew}
                        edge="end"
                        sx={styles.iconButton}>
                        {showNewPassword ? (
                          <IconComponent
                            iconName={'eye-off-outline'}
                            className={'icon'}
                            style={styles.iconComponent}
                          />
                        ) : (
                          <IconComponent
                            iconName={'eye-outline'}
                            className={'icon'}
                            style={styles.iconComponent}
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText error={errorNew}>
                  {errorNew ? t('heimdal_app.PassRecovery.' + errorNewMsg) : ''}
                </FormHelperText>
              </FormControl>
            </>
          )}
        </Grid>
        <Grid item xs={12} marginY={'1em'}>
          {isLoading ? (
            <Skeleton animation="wave" height={24} width="40%" />
          ) : (
            <Typography textAlign={'left'} variant="subtitle2">
              {t('heimdal_app.PassRecovery.confirm_password')}
            </Typography>
          )}
          {isLoading ? (
            <Skeleton animation="wave" height={40} width="100%" />
          ) : (
            <FormControl variant="outlined" fullWidth>
              <StyledOutlinedInput
                id="outlined-adornment-password"
                type={showPasswordConfirmation ? 'text' : 'password'}
                required
                error={errorPasswordConfirmation}
                fullWidth
                size={'small'}
                name="password"
                value={confirmNewPassword}
                onChange={e => setConfirmNewPassword(e.target.value)}
                placeholder={t('heimdal_app.Profile.password_placeholder')}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPasswordConfirmation}
                      onMouseDown={handleMouseDownPasswordConfirmation}
                      edge="end"
                      sx={styles.iconButton}>
                      {showPasswordConfirmation ? (
                        <IconComponent
                          iconName={'eye-off-outline'}
                          className={'icon'}
                          style={styles.iconComponent}
                        />
                      ) : (
                        <IconComponent
                          iconName={'eye-outline'}
                          className={'icon'}
                          style={styles.iconComponent}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText error={errorPasswordConfirmation}>
                {errorPasswordConfirmation
                  ? t('heimdal_app.PassRecovery.password_match')
                  : ''}
              </FormHelperText>
              <Tooltip
                title={t('heimdal_app.Profile.generate_new_password_info')}
                arrow
                placement="right">
                <Typography
                  variant="body2"
                  align="right"
                  onClick={handleGenerateNewPassword}
                  style={{ userSelect: 'none' }}
                  sx={styles.typography.secondary}
                  onMouseOver={e => {
                    e.target.style.cursor = 'pointer';
                  }}
                  onMouseOut={e => {
                    e.target.style.cursor = 'default';
                  }}>
                  {t('heimdal_app.Profile.generate_new_password')}
                </Typography>
              </Tooltip>
            </FormControl>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          justifyContent={'flex-end'}
          alignItems={'flex-end'}
          paddingBottom={1}
          display={'flex'}>
          {isLoading ? (
            <Skeleton animation="wave" height={48} width="10%" />
          ) : (
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ textTransform: 'capitalize' }}
              onClick={() => {
                setOpenConfirmation(true);
              }}>
              <Typography variant="inherit">
                {t('heimdal_app.Profile.save')}
              </Typography>
            </Button>
          )}
        </Grid>
      </Grid>

      <MFAVerify
        open={open}
        setOpen={setOpen}
        resolver={resolver}
        userProfile={userProfile}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        onVerificationComplete={() =>
          mode === 'create'
            ? handleCreateEmailAccount()
            : mode === 'update'
            ? handleUpdateProfileWithPassword()
            : undefined
        }
      />
      <ConfirmationModal
        open={openConfirmation}
        setOpen={setOpenConfirmation}
        title={'User information update'}
        content={
          confirmNewPassword === '' && currentPassword === ''
            ? t('heimdal_app.Profile.update_profile_confirmation')
            : t('heimdal_app.Profile.update_profile_and_password_confirmation')
        }
        onVerificationComplete={() => {
          setSubmit(true);
          setOpenConfirmation(false);
        }}
      />
    </>
  );
}
