import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Typography,
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
  Skeleton,
  Tooltip,
} from '@mui/material';
import {
  getAuth,
  unlink,
  GoogleAuthProvider,
  signInWithRedirect,
  signInWithEmailAndPassword,
  linkWithRedirect,
  linkWithPopup,
  signInWithPopup,
  getMultiFactorResolver,
  SAMLAuthProvider,
} from 'firebase/auth';
import MFAVerify from './MFAVerify';
import PasswordVerify from './PasswordVerify';
import ConfirmationModal from './ConfirmationModal';

export default function Profile(props) {
  const { t } = useTranslation();

  const {
    REACT_APP_EXTERNAL_USERS_TENANT_ID,
    REACT_APP_INTERNAL_USERS_TENANT_ID,
  } = process.env;

  const identityProviders = [
    {
      name: t('heimdal_app.Profile.user_password'),
      enabled: false,
      providerId: 'password',
    },
    {
      name: 'Google',
      enabled: false,
      providerId: 'google.com',
    },
  ];

  const {
    userProfile,
    isLoadingProfile,
    isLoading,
    setIsLoading,
    canModifyProvider,
    resolver,
    open,
    setOpen,
    setResolver,
    setErrorNew,
    setErrorNewMsg,
    newPassword,
    confirmNewPassword,
    setErrorPasswordConfirmation,
    setSubmit,
  } = props;
  const [language, setLanguage] = useState('en');
  const [userIdentityProviders, setUserIdentityProviders] =
    useState(identityProviders);
  const [activeProviders, setActiveProviders] = useState(0);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [mode, setMode] = useState(null);
  const [password, setPassword] = useState(null);
  const [openVerifyPassword, setOpenVerifyPassword] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const handleChange = event => {
    if (event.target.name === t('heimdal_app.Profile.user_password')) {
      setErrorNew(false);
      setErrorPasswordConfirmation(false);
      if (event.target.checked) {
        if (newPassword === '') {
          setErrorNew(true);
          setErrorNewMsg('new_password_description');
        } else if (newPassword !== confirmNewPassword) {
          setErrorPasswordConfirmation(true);
        } else if (newPassword === confirmNewPassword) {
          setMode('link');
          setSelectedProvider('password');
          setOpenConfirmation(true);
        }
      } else {
        sessionStorage.setItem('action', 'unlink');
        sessionStorage.setItem('provider', 'password');
        setSelectedProvider('password');
        setMode('unlink');
        setOpenConfirmation(true);
      }
    } else if (event.target.name === 'Google') {
      if (event.target.checked) {
        setSelectedProvider('google.com');
        setMode('link');
        setOpenConfirmation(true);
      } else {
        sessionStorage.setItem('action', 'unlink');
        sessionStorage.setItem('provider', 'google.com');
        setSelectedProvider('google.com');
        setMode('unlink');
        setOpenConfirmation(true);
      }
    } else if (event.target.name === 'Demo-SAML') {
      if (event.target.checked) {
        setSelectedProvider('saml.demo-saml');
        setMode('link');
        setOpenConfirmation(true);
      } else {
        sessionStorage.setItem('action', 'unlink');
        sessionStorage.setItem('provider', 'saml.demo-saml');
        setSelectedProvider('saml.demo-saml');
        setMode('unlink');
        setOpenConfirmation(true);
      }
    }
  };

  const updateIdentityProviders = () => {
    let newIdentityProviders = [...userIdentityProviders];
    const userProviders = userProfile.providerUserInfo;
    newIdentityProviders = newIdentityProviders.map(provider => {
      if (userProviders) {
        userProviders.forEach(userProvider => {
          if (provider.providerId === userProvider.providerId) {
            provider.enabled = true;
          }
        });
      }
      return provider;
    });
    if (userProfile.tenantId === REACT_APP_EXTERNAL_USERS_TENANT_ID) {
      newIdentityProviders = newIdentityProviders.filter(
        provider => provider.providerId !== 'password',
      );
    } else if (userProfile.tenantId === REACT_APP_INTERNAL_USERS_TENANT_ID) {
      newIdentityProviders = newIdentityProviders.filter(
        provider => provider.providerId !== 'google.com',
      );
    }
    setUserIdentityProviders(newIdentityProviders);
    let activeProviders = 0;
    newIdentityProviders.forEach(provider => {
      if (provider.enabled) {
        activeProviders++;
      }
    });
    setActiveProviders(activeProviders);
  };

  const unLinkProvider = userCredential => {
    if (userCredential) {
      unlink(userCredential.user, selectedProvider)
        .then(() => {
          sessionStorage.removeItem('action');
          sessionStorage.removeItem('provider');
          window.location.reload();
        })
        .catch(error => {
          console.log(error);
          sessionStorage.removeItem('action');
          sessionStorage.removeItem('provider');
        });
    } else {
      sessionStorage.removeItem('action');
      sessionStorage.removeItem('provider');
    }
  };

  const linkProvider = userCredential => {
    if (userCredential) {
      let provider;
      if (selectedProvider === 'google.com') {
        provider = new GoogleAuthProvider();
        sessionStorage.setItem('action', 'googleLink');
      } else if (selectedProvider === 'saml.demo-saml') {
        provider = new SAMLAuthProvider('saml.demo-saml');
        sessionStorage.setItem('action', 'samlLink');
      } else {
        return;
      }
      if (window.location.hostname !== 'localhost') {
        linkWithRedirect(userCredential.user, provider);
      } else {
        linkWithPopup(userCredential.user, provider)
          .then(result => {
            if (result) {
              try {
                if (selectedProvider === 'google.com') {
                  const credential =
                    GoogleAuthProvider.credentialFromResult(result);
                  if (!credential) {
                    console.log('could not get credential');
                    sessionStorage.removeItem('action');
                    sessionStorage.removeItem('provider');
                  } else {
                    sessionStorage.removeItem('action');
                    sessionStorage.removeItem('provider');
                    window.location.reload();
                  }
                } else if (selectedProvider === 'saml.demo-saml') {
                  console.log('saml.demo-saml');
                }
              } catch (error) {
                console.log(error);
                sessionStorage.removeItem('action');
                sessionStorage.removeItem('provider');
              }
            }
          })
          .catch(error => {
            sessionStorage.removeItem('action');
            sessionStorage.removeItem('provider');
            window.location.reload();
          });
      }
    } else {
      sessionStorage.removeItem('action');
      sessionStorage.removeItem('provider');
    }
  };

  const handleGoogleCredentials = () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    auth.tenantId = userProfile.tenantId;
    auth.languageCode = language;
    if (window.location.hostname !== 'localhost') {
      signInWithRedirect(auth, provider);
    } else {
      signInWithPopup(auth, provider)
        .then(result => {
          if (result) {
            props.setCanModifyProvider(true);
          }
        })
        .catch(error => {
          setResolver(getMultiFactorResolver(auth, error));
          props.setOpenOnProvider(true);
        });
    }
  };

  const handlePasswordCredentials = () => {
    const auth = getAuth();
    auth.tenantId = userProfile.tenantId;
    auth.languageCode = language;
    signInWithEmailAndPassword(auth, userProfile.email, password)
      .then(userCredential => {
        if (mode === 'link') {
          linkProvider(userCredential);
        } else if (mode === 'unlink') {
          unLinkProvider(userCredential);
        }
      })
      .catch(error => {
        if (error.code === 'auth/wrong-password') {
          setOpenVerifyPassword(true);
        } else if (error.code === 'auth/multi-factor-auth-required') {
          setResolver(getMultiFactorResolver(auth, error));
          setOpen(true);
        } else {
          console.log(error);
        }
      });
  };

  const handleModifyProvider = userCredential => {
    switch (selectedProvider) {
      case 'google.com':
        if (mode === 'link') {
          linkProvider(userCredential);
        } else if (mode === 'unlink') {
          unLinkProvider(userCredential);
        }
        break;
      case 'saml.demo-saml':
        if (mode === 'link') {
          linkProvider(userCredential);
        } else if (mode === 'unlink') {
          unLinkProvider(userCredential);
        }
        break;
      case 'password':
        if (mode === 'unlink') {
          unLinkProvider(userCredential);
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (userProfile) {
      updateIdentityProviders();
    }
  }, [isLoadingProfile]);

  useEffect(() => {
    if (canModifyProvider) {
      handleModifyProvider();
    }
  }, [canModifyProvider]);

  useEffect(() => {
    if (['link', 'unlink'].includes(sessionStorage.getItem('action'))) {
      setMode(sessionStorage.getItem('action'));
      setSelectedProvider(sessionStorage.getItem('provider'));
    }
  }, []);

  useEffect(() => {
    let languages = ['en', 'es', 'de'];
    let userLanguage = navigator.language || navigator.userLanguage || 'en-US';
    userLanguage = userLanguage.split('-')[0];
    userLanguage = languages.includes(userLanguage) ? userLanguage : 'en';

    setLanguage(userLanguage);
  }, []);

  return (
    <>
      <Grid container spacing={2} paddingY={4} paddingX={4}>
        <Grid item xs={12}>
          {isLoading ? (
            <Skeleton animation="wave" height={24} width="40%" />
          ) : (
            <Tooltip
              title={t('heimdal_app.Profile.linked_accounts_info')}
              arrow
              placement="left">
              <Typography variant="h6" textAlign={'left'}>
                {t('heimdal_app.Profile.linked_accounts')}
              </Typography>
            </Tooltip>
          )}
        </Grid>
        <Grid item xs={12} marginLeft={-2}>
          <FormControl component="fieldset" variant="standard" fullWidth>
            <FormGroup>
              {userIdentityProviders.map((provider, index) => (
                <FormControlLabel
                  control={
                    isLoading ? (
                      <Skeleton
                        animation="wave"
                        width="10%"
                        sx={{
                          marginRight: '-1em',
                        }}>
                        <Switch />
                      </Skeleton>
                    ) : (
                      <Tooltip
                        title={
                          provider.enabled
                            ? t('heimdal_app.Profile.disable_provider_info')
                            : t('heimdal_app.Profile.enable_provider_info')
                        }
                        arrow
                        placement="left">
                        <Switch
                          checked={provider.enabled}
                          onChange={handleChange}
                          name={provider.name}
                          color="primary"
                          sx={{
                            marginRight: '-1em',
                          }}
                        />
                      </Tooltip>
                    )
                  }
                  label={
                    isLoading ? (
                      <Skeleton width={100} height={32} />
                    ) : (
                      <Typography variant="subtitle2">
                        {provider.name}
                      </Typography>
                    )
                  }
                  labelPlacement="start"
                  style={{ justifyContent: 'space-between' }}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>
      <MFAVerify
        open={open}
        setOpen={setOpen}
        onVerificationComplete={handleModifyProvider}
        resolver={resolver}
        userProfile={userProfile}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
      <PasswordVerify
        open={openVerifyPassword}
        setOpen={setOpenVerifyPassword}
        onVerificationComplete={handlePasswordCredentials}
        password={password}
        setPassword={setPassword}
      />
      <ConfirmationModal
        open={openConfirmation}
        setOpen={setOpenConfirmation}
        title={t('heimdal_app.Profile.update_provider_confirmation')}
        content={
          mode === 'link'
            ? t('heimdal_app.Profile.link_provider_confirmation')
            : t('heimdal_app.Profile.unlink_provider_confirmation')
        }
        onVerificationComplete={() => {
          selectedProvider === 'password' && mode === 'link'
            ? setSubmit(true)
            : selectedProvider === 'google.com' ||
              selectedProvider === 'saml.demo-saml'
            ? setOpenVerifyPassword(true)
            : selectedProvider === 'password' && mode === 'unlink'
            ? handleGoogleCredentials()
            : console.log('Not supported');
          setOpenConfirmation(false);
        }}
      />
    </>
  );
}
