import React, {useEffect, useState} from 'react';
import { Provider } from 'react-redux';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { initializeApp } from 'firebase/app';
import '../App.css';

// zippedi tools js imports
import { ReduxLoginWeb as PrivateRoute } from '@zippeditoolsjs/web';
import { lightTheme, darkTheme } from '@zippeditoolsjs/web';

import Welcome from './redux/containers/login/Welcome';
import Recover from './redux/containers/login/Recover';
import Profile from './redux/containers/profile/Profile';
import ProfileParser from './redux/containers/profile/ProfileParser';

// instantiate services and wire up dependencies
import createStore from 'core/store';
import HTTPService from 'core/services/http';
import DataSource from 'core/services/dataSource';
import AuthSource from 'core/services/authSource';

import { useTranslation, withTranslation } from 'react-i18next';

const { REACT_APP_BASE_URL, REACT_APP_AUTH_BACKEND_URL } = process.env;

const http = new HTTPService();

const dataSource = new DataSource({
  baseUrl: REACT_APP_BASE_URL,
  http,
});

const authSource = new AuthSource({
  baseUrl: REACT_APP_AUTH_BACKEND_URL,
  http,
});

const store = createStore({
  authSource,
  dataSource,
});

const App = () => {
  const [theme, setTheme] = useState('light');
  const isDarkTheme = theme === 'dark';
  const toggleTheme = () => setTheme(isDarkTheme ? 'light' : 'dark');
  
  useEffect(() => {
    let languages = ['en', 'es', 'de', 'ja'];
    let userLanguage = navigator.language || navigator.userLanguage || 'en-US';
    userLanguage = userLanguage.split('-')[0];
    userLanguage = languages.includes(userLanguage) ? userLanguage : 'en';
  
    toogleTranslation(userLanguage);
  }, []);

  const { i18n } = useTranslation();
  const toogleTranslation = lang => i18n.changeLanguage(lang);

  const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT,
    storageBucket: process.env.REACT_APP_STORAGE,
    messagingSenderId: process.env.REACT_APP_MESSAGING,
    appId: process.env.REACT_APP_APP_ID,
  };
  if (process.env.DATABASE_URL)
    config['databaseURL'] = process.env.DATABASE_URL;
  if (process.env.MEASUREMENT_ID)
    config['measurementId'] = process.env.MEASUREMENT_ID;

  const app = initializeApp(config);
  return (
    <Provider store={store}>
      <ThemeProvider theme={isDarkTheme ? darkTheme : lightTheme}>
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Welcome />} />
            <Route exact path="/int" element={<Welcome />} />
            <Route exact path="/ext" element={<Welcome />} />
            <Route exact path="/recover" element={<Recover />} />
            <Route exact path="/profile" element={<ProfileParser />} />
            <Route
              exact
              path="/profile/int"
              element={
                <PrivateRoute
                  tenattype="int"
                  appUrlLogin={REACT_APP_BASE_URL}
                  appBaseUrl={REACT_APP_BASE_URL + '/profile/int'}>
                  <Profile
                    isDarkTheme={isDarkTheme}
                    toggleTheme={toggleTheme}
                    toogleTranslation={toogleTranslation}
                  />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/profile/ext"
              element={
                <PrivateRoute
                  tenattype="ext"
                  appUrlLogin={REACT_APP_BASE_URL}
                  appBaseUrl={REACT_APP_BASE_URL + '/profile/ext'}>
                  <Profile
                    isDarkTheme={isDarkTheme}
                    toggleTheme={toggleTheme}
                    toogleTranslation={toogleTranslation}
                  />
                </PrivateRoute>
              }
            />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
};

export default withTranslation()(App);
