import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Button,
  Typography,
  Skeleton,
  useTheme,
  Tooltip,
} from '@mui/material';
import ConfirmationModal from './ConfirmationModal';

export default function MFA(props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { userProfile, isLoading, revokeMFA, logOut } = props;
  const [open, setOpen] = useState(false);
  const [enroll, setEnroll] = useState(false);

  const styles = {
    typography: {
      secondary: {
        color:
          theme.palette.type === 'light'
            ? 'rgba(0, 0, 0, 0.6)'
            : 'rgba(255, 255, 255, 0.6)',
      },
    },
  };

  const handleRemoveMFA = async () => {
    setOpen(false);
    const tenantId = userProfile.tenantId;
    await revokeMFA({ tenantId });
    logOut().then(() => {
      window.location.reload();
    });
  };

  const handleEnrollMFA = async () => {
    setOpen(false);
    localStorage.removeItem('date_expiration');
    logOut().then(() => {
      window.location.reload();
    });
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        paddingY={2}
        paddingX={4}
        justifyContent={'space-between'}>
        <Tooltip
          title={t('heimdal_app.Profile.multi_factor_authentication_info')}
          arrow
          placement="left">
          <Grid item xs={12} md={12}>
            {isLoading ? (
              <Skeleton animation="wave" height={24} width="20%" />
            ) : (
              <Typography variant="h6" textAlign={'left'}>
                {t('heimdal_app.Profile.multi_factor_authentication')}
              </Typography>
            )}
          </Grid>
        </Tooltip>
        <Grid container item xs={12} md={12} spacing={2}>
          <Grid item xs={8}>
            {isLoading ? (
              <Skeleton animation="wave" height={24} width="60%" />
            ) : (
              <Typography variant="subtitle2" textAlign={'left'}>
                {t('heimdal_app.Profile.text_message')}
              </Typography>
            )}
            {isLoading ? (
              <Skeleton animation="wave" height={24} width="60%" />
            ) : (
              <Typography
                variant="body2"
                textAlign={'left'}
                sx={styles.typography.secondary}>
                {userProfile?.mfaInfo[0]?.phoneInfo
                  ? userProfile?.mfaInfo[0]?.phoneInfo.replace(
                      /^(\+\d{1,3})\d{6}/,
                      '$1******',
                    )
                  : t('heimdal_app.Profile.not_set')}
              </Typography>
            )}
          </Grid>
          <Grid
            item
            xs={4}
            justifyContent={'flex-end'}
            alignItems={'flex-end'}
            display={'flex'}>
            {isLoading ? (
              <Skeleton animation="wave" height={48} width="100%" />
            ) : (
              <Tooltip
                title={
                  userProfile?.mfaInfo[0]?.phoneInfo
                    ? t('heimdal_app.Profile.delete_mfa_info')
                    : t('heimdal_app.Profile.add_mfa_info')
                }
                arrow
                placement="left">
                <Button
                  variant="contained"
                  size="small"
                  style={{ textTransform: 'capitalize' }}
                  onClick={() => {
                    setOpen(true);
                    setEnroll(!userProfile?.mfaInfo[0]?.phoneInfo);
                  }}>
                  <Typography variant={'inherit'}>
                    {userProfile?.mfaInfo[0]?.phoneInfo
                      ? t('heimdal_app.Profile.delete')
                      : t('heimdal_app.Profile.add')}
                  </Typography>
                </Button>
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </Grid>
      {isLoading ? null : (
        <ConfirmationModal
          open={open}
          setOpen={setOpen}
          onVerificationComplete={enroll ? handleEnrollMFA : handleRemoveMFA}
          title={t('heimdal_app.Profile.multi_factor_authentication')}
          content={
            enroll
              ? t('heimdal_app.Profile.enroll_mfa_msg')
              : t('heimdal_app.Profile.remove_mfa_msg')
          }
        />
      )}
    </>
  );
}
