import { MuiTelInput } from 'mui-tel-input';
import { useTheme } from '@mui/material';

export default function StyledOutlinedInput(props) {
  const { children, inputBackground = 'white', Inputcolor } = props;
  const theme = useTheme();
  const lightBackground = inputBackground
    ? inputBackground
    : Object.values(theme.palette[inputBackground])[0];

  const style = {
    '& .MuiOutlinedInput-root': {
      background:
        theme.palette.type === 'light'
          ? lightBackground
          : theme.palette.paper.main,
      color: Inputcolor
        ? Inputcolor
        : theme.palette.type === 'light'
        ? theme.palette.primary.main + 'D9'
        : 'rgba(255, 255, 255, 0.87)',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor:
        theme.palette.type === 'light'
          ? 'rgba(0, 0, 0, 0.23)'
          : 'rgba(255, 255, 255, 0.23)',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main + 'D9',
    },
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main + 'D9',
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.error.main,
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: theme.palette.error.main,
    },
    '& .MuiTypography-root.MuiTypography-body1': {
      color:
        theme.palette.type === 'light'
          ? 'rgba(0, 0, 0, 0.87)'
          : 'rgba(255, 255, 255, 0.87)',
    },
    '& .MuiOutlinedInput-input': {
      color: theme.palette.type === 'light' ? 'black' : 'white',
    },
  };

  return (
    <MuiTelInput {...props} sx={style}>
      {children}
    </MuiTelInput>
  );
}
