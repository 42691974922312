export default function AuthSourceService({ baseUrl, http }) {
  const PATH_SIGN_IN = '/profile';
  const PATH_LOG_OUT = '/sessionLogout';
  const PATH_SESSION_LOGIN = '/sessionLogin';
  const PATH_NIST_CHECK_PASSWORD = '/nist_check_password';
  const PATH_PROFILE = '/userProfile';
  const PATH_REVOKE_MFA = '/revokeMFA';

  return {
    checkAuthenticated,
    logOut,
    getCSRFToken,
    getSessionCookie,
    nistCheckPassword,
    getProfile,
    revokeMFA,
    updateProfile,
  };

  function checkAuthenticated() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_SIGN_IN}`,
    });
  }

  function logOut() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_LOG_OUT}`,
    });
  }

  function getCSRFToken() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_SESSION_LOGIN}`,
    });
  }

  function getSessionCookie(body, csrf_token) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_SESSION_LOGIN}`,
      body,
      csrf_token,
    });
  }

  function nistCheckPassword(body) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_NIST_CHECK_PASSWORD}`,
      body,
    });
  }

  function getProfile() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_PROFILE}`,
    });
  }

  function updateProfile(body) {
    return http.patchAuthenticated({
      url: `${baseUrl}${PATH_PROFILE}`,
      body,
    });
  }

  function revokeMFA(body) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_REVOKE_MFA}`,
      body,
    });
  }
}
