import { connect } from 'react-redux';
import LoginForm from '../../../components/login/LoginForm';
import { isLoadingSession } from 'core/selectors';

export default connect(
    state => ({
        isLoadingSession: isLoadingSession(state),
    }),
    {},
)(LoginForm);
