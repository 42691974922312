import { useState } from 'react';
import {
  Grid,
  IconButton,
  useTheme,
  Modal,
  Typography,
  FormControl,
  InputLabel,
  InputAdornment,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';

import StyledOutlinedInput from '../tools/StyledOutlinedInput';
import StyledModalBox from '../tools/StyledModalBox';

export default function PasswordVerify(props) {
  const theme = useTheme();
  const { t } = useTranslation();
  const { open, setOpen, onVerificationComplete, password, setPassword } =
    props;
  const [showPassword, setShowPassword] = useState(false);

  const styles = {
    iconComponent: {
      color:
        theme.palette.type === 'dark' ? 'white' : theme.palette.primary.main,
    },
    iconButton: {
      ':hover': {
        backgroundColor:
          theme.palette.type === 'dark'
            ? `${theme.palette.primary.main}33`
            : `{theme.palette.grey[400]}33`,
      },
    },
  };

  const handleVerify = () => {
    onVerificationComplete();
    setOpen(false);
  };

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleClose = () => setOpen(false);

  return (
    <div>
      <Modal
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <StyledModalBox>
          <Grid container justifyContent="flex-end">
            <Grid item xs={12} md={12}>
              <Grid container>
                <Grid item xs={11}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    textAlign={'left'}>
                    {t('heimdal_app.Profile.verify_password')}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    aria-label="close"
                    sx={{
                      position: 'absolute',
                      right: '0.5rem',
                      top: '0.5rem',
                      color: 'text.secondary',
                    }}
                    onClick={() => {
                      handleClose();
                    }}>
                    <IconComponent
                      iconName={'close-outline'}
                      className={'icon'}
                    />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} paddingY={2}>
              <Typography
                id="modal-modal-description"
                variant="body2"
                textAlign={'left'}>
                {t('heimdal_app.Profile.verify_password_description')}
              </Typography>
              <Grid
                item
                xs={12}
                sx={{
                  marginX: '2',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '1rem',
                }}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel
                    htmlFor="outlined-adornment-password"
                    sx={{
                      color: theme.palette.type === 'light' ? 'black' : 'white',
                    }}>
                    {t('heimdal_app.Login.password')}
                  </InputLabel>
                  <StyledOutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    required
                    name="password"
                    value={password}
                    label={t('heimdal_app.Login.password')}
                    onChange={e => setPassword(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          style={styles.iconButton}>
                          {showPassword ? (
                            <IconComponent
                              iconName={'eye-off-outline'}
                              className={'icon'}
                              style={styles.iconComponent}
                            />
                          ) : (
                            <IconComponent
                              iconName={'eye-outline'}
                              className={'icon'}
                              style={styles.iconComponent}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between" display={'flex'}>
              <Grid item xs={3}>
                <Typography
                  variant="subtitle2"
                  textAlign={'left'}
                  color={theme.palette.secondary.main}
                  onClick={() => {
                    handleClose();
                  }}
                  style={{ cursor: 'pointer', userSelect: 'none' }}>
                  {t('heimdal_app.Profile.cancel')}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  variant="subtitle2"
                  textAlign={'right'}
                  onClick={handleVerify}
                  style={{ cursor: 'pointer', userSelect: 'none' }}>
                  {t('heimdal_app.Profile.confirm')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </StyledModalBox>
      </Modal>
    </div>
  );
}
