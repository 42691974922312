import qs from 'querystring';

export default function HTTPService() {
  const HTTP_METHOD_GET = 'GET';
  const HTTP_METHOD_POST = 'POST';
  const HTTP_METHOD_PATCH = 'PATCH';
  const HTTP_METHOD_DELETE = 'DELETE';
  const HTTP_HEADER_ACCEPT = 'Accept';
  const HTTP_HEADER_CONTENT_TYPE = 'Content-Type';
  const HTTP_CREDENTIALS = 'credentials';
  const HTTP_CREDENTIALS_TYPE = 'include';
  const HTTP_HEADER_CSRF_TOKEN = 'X-CSRFToken';

  const MIME_TYPE_JSON = 'application/json;charset=utf-8';

  return {
    getAuthenticated,
    postAuthenticated,
    deleteAuthenticated,
    patchAuthenticated,
  };

  function getAuthenticated({ url, query, csrf_token }) {
    return makeRequest({
      method: HTTP_METHOD_GET,
      url,
      csrf_token,
      query,
    });
  }

  function postAuthenticated({ url, body, csrf_token }) {
    return makeRequest({
      method: HTTP_METHOD_POST,
      url,
      csrf_token,
      body,
    });
  }

  function patchAuthenticated({ url, body, csrf_token }) {
    return makeRequest({
      method: HTTP_METHOD_PATCH,
      url,
      csrf_token,
      body,
    });
  }

  function deleteAuthenticated({ url, body, csrf_token }) {
    return makeRequest({
      method: HTTP_METHOD_DELETE,
      url,
      csrf_token,
      body,
    });
  }

  async function makeRequest({
    method,
    url,
    csrf_token,
    query = {},
    body = {},
    headers = {},
  }) {
    const finalUrl =
      query && Object.keys(query).length
        ? `${url}?${qs.stringify(query)}`
        : url;

    const finalHeaders = Object.keys(headers).reduce((acc, headerKey) => {
      acc.append(headerKey, headers[headerKey]);
      return acc;
    }, new Headers());

    finalHeaders.set(HTTP_HEADER_ACCEPT, MIME_TYPE_JSON);
    finalHeaders.set(HTTP_HEADER_CSRF_TOKEN, csrf_token);

    if (
      method === HTTP_METHOD_POST ||
      method === HTTP_METHOD_PATCH ||
      method === HTTP_METHOD_DELETE
    ) {
      finalHeaders.set(HTTP_HEADER_CONTENT_TYPE, MIME_TYPE_JSON);
    }

    const requestDescription =
      method === HTTP_METHOD_POST ||
      method === HTTP_METHOD_PATCH ||
      method === HTTP_METHOD_DELETE
        ? {
            method,
            headers: finalHeaders,
            body: JSON.stringify(body),
            [HTTP_CREDENTIALS]: HTTP_CREDENTIALS_TYPE,
          }
        : {
            method,
            headers: finalHeaders,
            [HTTP_CREDENTIALS]: HTTP_CREDENTIALS_TYPE,
          };

    const response = await fetch(finalUrl, requestDescription);

    let responseBody = {};
    try {
      responseBody = await response.json();
    } catch (error) {}

    if (response.ok) {
      return responseBody;
    } else {
      const responseText = await response.text();
      throw new Error(responseText);
    }
  }
}
