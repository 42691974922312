import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import Cookie from 'js-cookie';
import {
  getAuth,
  getRedirectResult,
  signInWithEmailAndPassword,
  RecaptchaVerifier,
  multiFactor,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  getMultiFactorResolver,
} from 'firebase/auth';
import { Grid, Link, Typography } from '@mui/material';

import { support_phone_number, whatsAppUrl } from 'core/slices/constants';
import LoginForm from '../../redux/containers/login/LoginForm';
import PhoneNumberForm from '../../redux/containers/login/PhoneNumberForm';
import OPTCode from '../../redux/containers/login/OPTCode';
import ExternalUserConfirmation from '../../redux/containers/login/ExternalUserConfirmation';

import Copyright from '../utils/Copyright';

function DateStrFormat(addYear = 0) {
  const today = new Date();
  const yyyy = today.getFullYear() + addYear;
  let mm = today.getMonth() + 1;
  let dd = today.getDate();

  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;

  const formattedToday = yyyy + '-' + mm + '-' + dd;
  return formattedToday;
}

export default function Welcome(props) {
  const { t } = useTranslation();
  const [isLoadingRedirect, setIsLoadingRedirect] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [storedTdToken, setStoredTdToken] = useState(null);
  const [storedRefreshToken, setStoredRefreshToken] = useState(null);
  const [showPhoneNumber, setShowPhoneNumber] = useState(false);
  const [PhoneNumber, setPhoneNumber] = useState('');
  const [email, setMail] = useState('');
  const [password, setPw] = useState('');
  const [isExt, setIsExt] = useState(false);
  const phoneRef = useRef();
  const [language, setLanguage] = useState('en');
  const [showCode, setShowCode] = useState(false);
  const [verificationID, setVerificationID] = useState('');
  const [resolverAuth, setResolverAuth] = useState(null);
  const [showRecaptchaLogin, setShowRecaptchaLogin] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [sendingOPT, setOPT] = useState(false);
  const [captchaCode, setCaptchaCode] = useState('');
  const [showPhoneNumberExt, setShowPhoneNumberExt] = useState(false);
  const [recaptchaVerifierObject, setRecaptchaVerifierObject] = useState(null);
  const [finishedSMS, setFinishedSMS] = useState(false);
  const [countdown, setCountdown] = useState(15);
  const [showButton, setShowButton] = useState(false);
  const [dateExpiration, setDateExpiration] = useState('');
  const [isverifySMSCode, setIsverifySMSCode] = useState(false);

  const auth1 = getAuth();

  const isLargeScreen = useMediaQuery('(min-width: 1200px)'); // 1200px = lg

  const handleChangeEmail = event => {
    setMail(event.target.value);
  };
  const handleChangePassword = event => {
    setPw(event.target.value);
  };

  const fullWhatsAppUrl = `${whatsAppUrl}${support_phone_number}?text=${t(
    'heimdal_app.Login.help_initial_text',
  )}`;

  let search = useLocation();

  let query = new URLSearchParams(search.search);

  let UrlRedirect = query.get('redirect');

  let base64Matcher = new RegExp(
    '^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{4})$',
  );

  let decodeUrlRedirect = UrlRedirect
    ? base64Matcher.test(UrlRedirect)
      ? Buffer.from(UrlRedirect, 'base64').toString('ascii')
      : UrlRedirect
    : UrlRedirect;

  let tenantID = '';
  if (decodeUrlRedirect === null) {
    decodeUrlRedirect = '/profile';
  }

  if (search.pathname === '/ext') {
    tenantID = process.env.REACT_APP_EXTERNAL_USERS_TENANT_ID;
  } else {
    tenantID = process.env.REACT_APP_INTERNAL_USERS_TENANT_ID;
  }

  useEffect(() => {
    setIsLoading(props.isLoadingSession);
  }, [props.isLoadingSession]);

  useEffect(() => {
    props.getCSRFToken();
  }, []);

  useEffect(() => {
    let languages = ['en', 'es', 'de', 'ja'];
    let userLanguage = navigator.language || navigator.userLanguage || 'en-US';
    userLanguage = userLanguage.split('-')[0];
    userLanguage = languages.includes(userLanguage) ? userLanguage : 'en';

    setLanguage(userLanguage);
  }, []);

  useEffect(() => {
    if (resolverAuth !== null) {
      setShowRecaptchaLogin(false);
      setShowCode(true);
      setIsLogin(true);
    }
  }, [resolverAuth]);

  useEffect(() => {
    const timer =
      countdown > 0 && setInterval(() => setCountdown(countdown - 1), 1000);
    if (countdown === 0) {
      setShowButton(true);
      clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [countdown]);

  useEffect(() => {
    setDateExpiration(localStorage.getItem('date_expiration'));
  }, [email, password]);

  useEffect(() => {
    if (props.hasSessionCookie) {
      setOPT(false);
      setIsLoadingRedirect(true);
      window.location.replace(decodeUrlRedirect);
      if (isverifySMSCode) {
        setShowCode(false);
        setCaptchaCode('');
      }
    }
  }, [props.hasSessionCookie]);

  useEffect(() => {
    // Verificar si estan bien estos errores
    if (props.hasSessionError) {
      setOPT(false);
      const error = props.hasSessionError;
      if (error.code === 'auth/operation-not-allowed') {
        alert(t('heimdal_app.Login.operation_not_allowed'));
      } else {
        alert(t('heimdal_app.Login.try_again_later'));
      }
    }
  }, [props.hasSessionError]);

  useEffect(() => {
    setIsLoadingRedirect(true);
    getRedirectResult(auth1)
      .then(result => {
        try {
          const user = result.user;
          if (result) {
            setStoredTdToken(result._tokenResponse.idToken);
            setStoredRefreshToken(result._tokenResponse.refreshToken);
          }
          const mfaList = user.reloadUserInfo.mfaInfo;
          let today = new Date();
          let isOnDate;
          const date_expiration = new Date(
            localStorage.getItem('date_expiration'),
          );
          const formattedToday = DateStrFormat();
          today = new Date(formattedToday);
          const diffInMs = date_expiration.getTime() - today.getTime();
          if (diffInMs > 0) {
            isOnDate = true;
          } else {
            isOnDate = false;
          }
          if (!mfaList) {
            if (
              !user.email.endsWith("@zippedi.com")
            )
              if (isOnDate === false) {
                setShowPhoneNumberExt(true);
              } else {
                const body = {
                  idToken: result._tokenResponse.idToken,
                  refreshToken: result._tokenResponse.refreshToken,
                };
                props.getSessionCookie(body);
              }
            else {
              setIsLoading(true);
              setShowPhoneNumber(true);
            }
          } else {
            const idToken = result._tokenResponse.idToken;
            const refreshToken = result._tokenResponse.refreshToken;
            const body = { idToken, refreshToken };
            props.getSessionCookie(body);
          }
        } catch {
          setIsLoading(false);
        }
      })
      .catch(error => {
        if (error.code === 'auth/user-disabled') {
          alert(t('heimdal_app.Login.user_disabled'));
        } else if (error.code === 'auth/multi-factor-auth-required') {
          setIsLoading(true);
          const resolver = getMultiFactorResolver(auth1, error);
          setResolverAuth(resolver);
        } else {
          console.log(error.code);
        }
        setIsLoading(false);
      });
    setIsLoadingRedirect(false);
  }, []);

  function setUpRecaptha() {
    setIsLoading(false);
    const auth = getAuth();
    auth.languageCode = language;
    auth.tenantId = tenantID;
    const recaptchaVerifier = new RecaptchaVerifier(
      'send-info-button',
      {
        size: 'invisible',
        callback: function (response) {
          let newPhone = phoneRef.current;
          const user = auth.currentUser;
          const PhoneNumberRemoveSpace = newPhone.replace(/\s+/g, '');
          try {
            multiFactor(user)
              .getSession()
              .then(function (multiFactorSession) {
                try {
                  const phoneInfoOptions = {
                    phoneNumber: PhoneNumberRemoveSpace,
                    session: multiFactorSession,
                  };
                  const phoneAuthProvider = new PhoneAuthProvider(auth);
                  return phoneAuthProvider
                    .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
                    .then(verificationId => {
                      try {
                        setVerificationID(verificationId);
                        setShowCode(true);
                        setShowPhoneNumber(false);
                      } catch (error) {
                        console.log(
                          'error adding phone and sending SMS. ',
                          error,
                        );
                      }
                    })
                    .catch(error => {
                      if (error.code === 'auth/too-many-requests') {
                        alert(t('heimdal_app.Login.too_many_requests'));
                      }
                    });
                } catch (error) {
                  console.log('error adding MFA ' + error);
                }
              });
          } catch (error) {
            console.log('error sending SMS. ', error);
          }
        },
        'expired-callback': () => {
          console.log('Recaptcha timeout');
        },
      },
      auth,
    );
    recaptchaVerifier.render();
    return recaptchaVerifier;
  }

  async function verifySMSCode(captchaCode) {
    setIsverifySMSCode(true);
    setOPT(true);
    const auth = getAuth();
    auth.languageCode = language;
    auth.tenantId = tenantID;
    const user = auth.currentUser;
    try {
      const cred = PhoneAuthProvider.credential(verificationID, captchaCode);
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      if (isLogin) {
        resolverAuth
          .resolveSignIn(multiFactorAssertion)
          .then(userCredential => {
            const idToken = userCredential._tokenResponse.idToken;
            const refreshToken = userCredential._tokenResponse.refreshToken;
            const body = { idToken, refreshToken };
            props.getSessionCookie(body);
          })
          .catch(error => {
            if (error.code === 'auth/invalid-verification-code') {
              setOPT(false);
              setCaptchaCode('');
              alert(t('heimdal_app.Login.wrong_code'));
            } else {
              setOPT(false);
              console.log(error);
              alert('An error happened inscribing your MFA, please try again.');
            }
            setIsverifySMSCode(false);
          });
      } else {
        const cred = PhoneAuthProvider.credential(verificationID, captchaCode);
        const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
        multiFactor(user)
          .enroll(multiFactorAssertion, 'SMS')
          .then(_ => {
            try {
              const body = {
                idToken: user.stsTokenManager.accessToken,
                refreshToken: user.stsTokenManager.refreshToken,
              };
              props.getSessionCookie(body);
            } catch (error) {
              setOPT(false);
              alert('An error happened logging in, please try again.');
              setIsverifySMSCode(false);
            }
          })
          .catch(error => {
            if (error.code === 'auth/invalid-verification-code') {
              setOPT(false);
              setCaptchaCode('');
              alert(t('heimdal_app.Login.wrong_code'));
            } else {
              setOPT(false);
              console.log(error);
              alert('An error happened inscribing your MFA, please try again.');
            }
            setIsverifySMSCode(false);
          });
      }
    } catch (error) {
      setOPT(false);
      console.log('error enrollment with code. ', error);
      setIsverifySMSCode(false);
    }
  }

  const addPhoneNumberExt = async () => {
    setShowPhoneNumberExt(false);
    setShowPhoneNumber(true);
    setIsExt(true);
  };

  const SignInWithEmail = event => {
    setIsLoadingRedirect(true);
    event.preventDefault();
    const auth = getAuth();
    auth.tenantId = tenantID;
    if (email !== '' && password !== '') {
      signInWithEmailAndPassword(auth, email, password)
        .then(userCredential => {
          const idToken = userCredential._tokenResponse.idToken;
          const refreshToken = userCredential._tokenResponse.refreshToken;
          setStoredTdToken(idToken);
          setStoredRefreshToken(refreshToken);
          const mfaList = userCredential.user.reloadUserInfo.mfaInfo;
          let today = new Date();
          let isOnDate;
          const date_expiration = new Date(
            localStorage.getItem('date_expiration'),
          );
          const formattedToday = DateStrFormat();
          today = new Date(formattedToday);
          const diffInMs = date_expiration.getTime() - today.getTime();
          if (diffInMs > 0) {
            isOnDate = true;
          } else {
            isOnDate = false;
          }
          if (!mfaList) {
            if (
              !userCredential.user.email.endsWith('@zippedi.com')
            )
              if (isOnDate === false) {
                setShowPhoneNumberExt(true);
              } else {
                const body = { idToken, refreshToken };
                props.getSessionCookie(body);
              }
            else {
              setIsLoading(true);
              setShowPhoneNumber(true);
            }
          } else {
            const body = { idToken, refreshToken };
            props.getSessionCookie(body);
          }
        })
        .catch(error => {
          if (error.code === 'auth/too-many-requests') {
            alert(t('heimdal_app.Login.too_many_requests'));
          } else if (error.code === 'auth/user-disabled') {
            alert(t('heimdal_app.Login.user_disabled'));
          } else if (error.code === 'auth/multi-factor-auth-required') {
            const resolver = getMultiFactorResolver(auth, error);
            setResolverAuth(resolver);
          } else if (error.code === 'auth/user-token-expired') {
            alert(t('heimdal_app.Login.try_again_later'));
          } else {
            console.log('error code: ', error.code);
            console.log('error: ', error);
            alert(t('heimdal_app.Login.invalid_credentials'));
          }
          setIsLoadingRedirect(false);
        });
      setIsLoadingRedirect(true);
    }
  };

  const logInValidation = async resolverAuth => {
    const auth = getAuth();
    auth.languageCode = language;
    auth.tenantId = tenantID;
    let recaptchaVerifier = recaptchaVerifierObject;
    if (recaptchaVerifierObject === null) {
      recaptchaVerifier = await new RecaptchaVerifier(
        'recaptcha-button-resend',
        {
          size: 'invisible',
          callback: function (_) {
            if (
              resolverAuth &&
              resolverAuth.hints[0].factorId ===
              PhoneMultiFactorGenerator.FACTOR_ID
            ) {
              setPhoneNumber(resolverAuth.hints[0].phoneNumber);
              const phoneInfoOptions = {
                multiFactorHint: resolverAuth.hints[0],
                session: resolverAuth.session,
              };
              const phoneAuthProvider = new PhoneAuthProvider(auth);
              phoneAuthProvider
                .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
                .then(verificationId => {
                  setVerificationID(verificationId);
                  setIsLogin(true);
                  setShowRecaptchaLogin(false);
                  setShowCode(true);
                })
                .catch(error => {
                  if (error.code === 'auth/too-many-requests') {
                    alert('Too many requests. Try again later.');
                  } else {
                    alert(error.code);
                  }
                });
            } else {
              let newPhone = phoneRef.current ? phoneRef.current : '';
              const user = auth.currentUser;
              const PhoneNumberRemoveSpace = newPhone.replace(/\s+/g, '');
              if (PhoneNumberRemoveSpace.length > 0) {
                try {
                  multiFactor(user)
                    .getSession()
                    .then(function (multiFactorSession) {
                      try {
                        const phoneInfoOptions = {
                          phoneNumber: PhoneNumberRemoveSpace,
                          session: multiFactorSession,
                        };
                        const phoneAuthProvider = new PhoneAuthProvider(auth);
                        phoneAuthProvider
                          .verifyPhoneNumber(
                            phoneInfoOptions,
                            recaptchaVerifier,
                          )
                          .then(verificationId => {
                            setVerificationID(verificationId);
                            setShowCode(true);
                            setShowPhoneNumber(false);
                          })
                          .catch(error => {
                            if (error.code === 'auth/too-many-requests') {
                              alert('Too many requests. Try again later.');
                            } else {
                              alert(error.code);
                            }
                          });
                      } catch (error) {
                        console.log('error adding MFA ' + error);
                      }
                    });
                } catch (error) {
                  console.log('error sending SMS. ', error);
                }
              }
            }
          },
        },
        auth,
      );
      setRecaptchaVerifierObject(recaptchaVerifier);
      recaptchaVerifier.render();
    }
  };

  const sendFirstSMS = async resolverAuth => {
    try {
      const auth = getAuth();
      auth.languageCode = language;
      auth.tenantId = tenantID;
      window.recaptchaVerifier = await new RecaptchaVerifier(
        'first-sms',
        { size: 'invisible' },
        auth,
      );
      if (
        resolverAuth &&
        resolverAuth.hints[0].factorId === PhoneMultiFactorGenerator.FACTOR_ID
      ) {
        setPhoneNumber(resolverAuth.hints[0].phoneNumber);
        const phoneInfoOptions = {
          multiFactorHint: resolverAuth.hints[0],
          session: resolverAuth.session,
        };
        const phoneAuthProvider = new PhoneAuthProvider(auth);
        phoneAuthProvider
          .verifyPhoneNumber(phoneInfoOptions, window.recaptchaVerifier)
          .then(verificationId => {
            setVerificationID(verificationId);
            setIsLogin(true);
            setShowRecaptchaLogin(false);
            setShowCode(true);
            setFinishedSMS(true);
          })
          .catch(error => {
            if (error.code === 'auth/too-many-requests') {
              alert('Too many requests. Try again later.');
            } else {
              alert(error.code);
            }
          });
      } else {
        setFinishedSMS(true);
      }
    } catch (error) {
      setFinishedSMS(true);
      alert(error.code);
    }
  };

  const redirectWithEmail = async () => {
    const auth = getAuth();
    auth.tenantId = tenantID;

    signInWithEmailAndPassword(auth, email, password).then(userCredential => {
      const idToken = userCredential._tokenResponse.idToken;
      const refreshToken = userCredential._tokenResponse.refreshToken;
      const body = { idToken, refreshToken };

      props.getSessionCookie(body);
    });
  };

  const saveAndRedirect = async () => {
    const confirmed = window.confirm(
      t('heimdal_app.Login.confirm_dont_ask_again'),
    );

    if (confirmed) {
      const formattedToday = DateStrFormat(1);
      localStorage.setItem('date_expiration', formattedToday);
      redirectWithEmail();
    }
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      spacing={isLargeScreen ? '-2em' : 0}
      height={'100vh'}>
      <Grid item md={0} lg={6} xl={5} alignContent={'center'}>
        {isLargeScreen ? (
          <img
            src="/images/logo_zippedi.png"
            alt="LOGO"
            width="80%"
            height="auto"
            style={{ marginLeft: '2em' }}
          />
        ) : null}
      </Grid>
      <Grid item xs={10} md={8} lg={6} xl={4}>
        {showPhoneNumber &&
          !showCode &&
          !showRecaptchaLogin &&
          !showPhoneNumberExt ? (
          <PhoneNumberForm
            PhoneNumber={PhoneNumber}
            isExt={isExt}
            setPhoneNumber={setPhoneNumber}
            setUpRecaptha={setUpRecaptha}
            setIsLoading={setIsLoading}
            phoneRef={phoneRef}
          />
        ) : !showPhoneNumber &&
          showCode &&
          !showRecaptchaLogin &&
          !showPhoneNumberExt ? (
          <OPTCode
            code={captchaCode}
            resolver={resolverAuth}
            showCode={showCode}
            PhoneNumber={PhoneNumber}
            setCaptchaCode={setCaptchaCode}
            verifySMSCode={verifySMSCode}
            setShowRecaptchaLogin={setShowRecaptchaLogin}
            setShowCode={setShowCode}
            sendingOPT={sendingOPT}
            logInValidation={logInValidation}
            finishedSMS={finishedSMS}
            sendFirstSMS={sendFirstSMS}
            countdown={countdown}
            showButton={showButton}
            setShowButton={setShowButton}
            setCountdown={setCountdown}
            setIsLoading={setIsLoading}
            setUpRecaptha={setUpRecaptha}
          />
        ) : showPhoneNumberExt &&
          !showPhoneNumber &&
          !showCode &&
          !showRecaptchaLogin ? (
          <ExternalUserConfirmation
            saveAndRedirect={saveAndRedirect}
            addPhoneNumberExt={addPhoneNumberExt}
            setIsLoading={setIsLoading}
          />
        ) : (
          <LoginForm
            language={language}
            tenantID={tenantID}
            email={email}
            password={password}
            handleChangeEmail={handleChangeEmail}
            handleChangePassword={handleChangePassword}
            SignInWithEmail={SignInWithEmail}
            setIsLoading={setIsLoading}
            setStoredTdToken={setStoredTdToken}
            setStoredRefreshToken={setStoredRefreshToken}
            setShowPhoneNumberExt={setShowPhoneNumberExt}
            setShowPhoneNumber={setShowPhoneNumber}
            setResolverAuth={setResolverAuth}
            setIsLoadingRedirect={setIsLoadingRedirect}
            isLoadingRedirect={isLoadingRedirect}
          />
        )}
        <Grid item xs={12} marginTop={'2em'}>
          <Copyright />
        </Grid>
        <Grid item xs={12} marginTop={'1em'} alignItems={'center'}>
          <Typography align="center" variant="body2">
            <Link href={fullWhatsAppUrl}>
              {t('heimdal_app.Login.need_help')}
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
