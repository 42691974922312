import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid, IconButton, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';

import StyledModalBox from '../tools/StyledModalBox';

export default function ConfirmationModal(props) {
  const { open, setOpen, onVerificationComplete, title, content } = props;
  const theme = useTheme();
  const { t } = useTranslation();

  const handleClose = () => setOpen(false);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}>
        <StyledModalBox>
          <Grid container justifyContent="flex-end">
            <Grid item xs={12} md={12}>
              <Grid container>
                <Grid item xs={11}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    textAlign={'left'}>
                    {title}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    aria-label="close"
                    sx={{
                      position: 'absolute',
                      right: '0.5rem',
                      top: '0.5rem',
                      color: 'text.secondary',
                    }}
                    onClick={() => {
                      handleClose();
                    }}>
                    <IconComponent
                      iconName={'close-outline'}
                      className={'icon'}
                    />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} paddingY={2}>
              <Typography
                id="modal-modal-description"
                variant="body2"
                textAlign={'justify'}>
                {content}
              </Typography>
            </Grid>
            <Grid container justifyContent="space-between" display={'flex'}>
              <Grid item xs={3}>
                <Typography
                  onClick={() => {
                    handleClose();
                  }}
                  variant="subtitle2"
                  textAlign={'left'}
                  color={theme.palette.secondary.main}
                  style={{ cursor: 'pointer', userSelect: 'none' }}>
                  {t('heimdal_app.Profile.cancel')}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  variant="subtitle2"
                  textAlign={'right'}
                  onClick={() => {
                    onVerificationComplete();
                  }}
                  style={{ cursor: 'pointer', userSelect: 'none' }}>
                  {t('heimdal_app.Profile.confirm')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </StyledModalBox>
      </Modal>
    </div>
  );
}
