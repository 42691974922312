import { connect } from 'react-redux';
import ProfileForm from '../../../components/profile/ProfileForm';
import { patchProfile } from 'core/slices/profile';
import { nistCheckPassword } from 'core/slices/session';
import { userProfile, isLoadingProfile } from 'core/selectors';
import { hasValidatePasswordResponse } from 'core/selectors';

export default connect(
  state => ({
    userProfile: userProfile(state),
    isLoadingProfile: isLoadingProfile(state),
    hasValidatePasswordResponse: hasValidatePasswordResponse(state),
  }),
  { patchProfile, nistCheckPassword },
)(ProfileForm);
