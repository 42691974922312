import React, { Component } from 'react';
import {
  Button,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Typography,
  Avatar,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { withTranslation } from 'react-i18next';

//Icons
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';

// Styled components
const ColorButton = styled(Button)(({ theme }) => ({
  color: 'primary',
  height: theme.mixins.toolbar.minHeight,
}));

const StyledMenu = styled(props => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor:
      theme.palette.type === 'light' ? '#1a242d' : theme.palette.paper.main,
    borderRadius: 3,
    marginTop: theme.spacing(1),
    minWidth: 300,
    color: theme.palette.mode === 'light' ? 'white' : 'black',
  },
}));

const styles = {
  text: {
    fontSize: 14,
    marginLeft: 12,
  },
};

class MyMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isLanguageMenuOpen: false,
      anchorEl: null,
      username: this.props.userInfo.name,
      picture: this.props.userInfo.picture,
    };
  }

  handleOpenMenu = event => {
    this.setState({ open: true });
    this.setAnchorEl(event.currentTarget);
  };

  handleCloseMenu = () => {
    this.setState({ open: false });
  };

  handleToggleLanguageMenu = () => {
    this.setState({ isLanguageMenuOpen: !this.state.isLanguageMenuOpen });
  };

  setAnchorEl = event => {
    this.setState({ anchorEl: event });
  };

  render() {
    const { open, anchorEl } = this.state;
    const { toggleTheme, toogleTranslation, t } = this.props;

    const lngs = {
      en: { nativeName: 'English' },
      de: { nativeName: 'Deutsch' },
      es: { nativeName: 'Spanish' },
      ja: { nativeName: '日本語' },
    };

    return (
      <Grid>
        <ColorButton onClick={this.handleOpenMenu}>
          <Avatar
            src={this.state.picture}
            imgProps={{ referrerPolicy: 'no-referrer' }}
          />
        </ColorButton>
        {this.state.isLanguageMenuOpen ? (
          <StyledMenu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={this.handleCloseMenu}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}>
            <MenuItem onClick={this.handleToggleLanguageMenu}>
              <IconComponent
                iconName={'chevron-back-outline'}
                className={'icon'}
              />
            </MenuItem>
            {Object.keys(lngs).map(lng => (
              <div key={lng}>
                <MenuItem
                  onClick={() => {
                    toogleTranslation(lng);
                    this.handleCloseMenu();
                    this.handleToggleLanguageMenu();
                  }}>
                  {lngs[lng].nativeName}
                </MenuItem>
                <Divider variant="middle" style={{ background: '#cacadc' }} />
              </div>
            ))}
          </StyledMenu>
        ) : (
          <StyledMenu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={this.handleCloseMenu}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}>
            <MenuItem onClick={this.handleCloseMenu}>
              <IconComponent iconName={'person'} className={'icon'} />
              <Typography style={styles.text}>{this.state.username}</Typography>
            </MenuItem>

            <Divider variant="middle" style={{ background: 'white' }} />

            <MenuItem onClick={this.handleToggleLanguageMenu}>
              <IconComponent iconName={'language'} className={'icon'} />
              <Typography style={styles.text}>
                {t('heimdal_app.Menu.change_language')}
              </Typography>
            </MenuItem>

            <MenuItem onClick={toggleTheme}>
              <IconComponent iconName={'moon-outline'} className={'icon'} />
              <Typography style={styles.text}>
                {t('heimdal_app.Menu.change_theme')}
              </Typography>
            </MenuItem>

            <MenuItem onClick={() => this.props.logOut()}>
              <IconComponent iconName={'log-out-outline'} className={'icon'} />
              <Typography style={styles.text}>
                {t('heimdal_app.Menu.log_out')}
              </Typography>
            </MenuItem>
          </StyledMenu>
        )}
      </Grid>
    );
  }
}

export default withTranslation()(MyMenu);
