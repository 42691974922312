import { useEffect, useState } from 'react';
import { Grid, IconButton, useTheme, Modal, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';
import ReactCodeInput from 'react-code-input';
import {
  getAuth,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
} from 'firebase/auth';

import StyledModalBox from '../tools/StyledModalBox';

export default function MFAVerify(props) {
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    open,
    setOpen,
    userProfile,
    resolver,
    setIsLoading,
    onVerificationComplete,
  } = props;
  const [code, setCode] = useState('');
  const [verificationId, setVerificationId] = useState('');
  const [countdown, setCountdown] = useState(15);
  const [language, setLanguage] = useState('en');
  const [showButton, setShowButton] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const { inputBackground = 'white' } = props;
  const lightBackground = inputBackground
    ? inputBackground
    : Object.values(theme.palette[inputBackground])[0];

  const styles = {
    codeInput: {
      fontFamily: 'monospace',
      MozAppearance: 'textfield',
      borderRadius: '6px',
      border: '1px solid',
      boxShadow: '0px 0px 10px 0px rgba(0,0,0,.10)',
      margin: '4px',
      paddingLeft: '8px',
      width: '36px',
      height: '42px',
      fontSize: '32px',
      boxSizing: 'border-box',
      color: theme.palette.type === 'light' ? 'black' : 'white',
      backgroundColor:
        theme.palette.type === 'light'
          ? lightBackground
          : theme.palette.paper.main,
      borderColor: 'lightgrey',
    },
    loadingButton: !showButton
      ? {
          color:
            theme.palette.type === 'light'
              ? 'rgba(0, 0, 0, 0.13)'
              : 'rgba(255, 255, 255, 0.13)',
        }
      : {
          color: theme.palette.type === 'light' ? 'black' : 'white',
        },
  };

  const handleVerify = () => {
    setIsVerifying(true);
    const cred = PhoneAuthProvider.credential(verificationId, code);
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
    resolver
      .resolveSignIn(multiFactorAssertion)
      .then(function (userCredential) {
        setIsVerifying(false);
        onVerificationComplete(userCredential);
      })
      .catch(function (error) {
        setIsVerifying(false);
        console.log(error);
      });
  };

  const sentSMS = () => {
    const auth = getAuth();
    auth.tenantId = userProfile.tenantId;
    auth.languageCode = language;
    if (resolver.hints[0].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
      const phoneInfoOptions = {
        multiFactorHint: resolver.hints[0],
        session: resolver.session,
      };
      const phoneAuthProvider = new PhoneAuthProvider(auth);
      return phoneAuthProvider
        .verifyPhoneNumber(phoneInfoOptions, window.recaptchaVerifier)
        .then(function (verificationId) {
          setVerificationId(verificationId);
          setIsLoading(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      setIsLoading(false);
      console.log("User doesn't have a second factor enrolled");
    }
  };

  const restartTimer = () => {
    setShowButton(false);
    setCountdown(15);
    sentSMS();
  };

  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (open && resolver) {
      restartTimer();
    }
  }, [open, resolver]);

  useEffect(() => {
    if (countdown <= 0) {
      setShowButton(true);
      return;
    }
    const timer = setTimeout(() => {
      setCountdown(countdown - 1);
    }, 1000);
    return () => clearTimeout(timer);
  }, [countdown]);

  useEffect(() => {
    let languages = ['en', 'es', 'de'];
    let userLanguage = navigator.language || navigator.userLanguage || 'en-US';
    userLanguage = userLanguage.split('-')[0];
    userLanguage = languages.includes(userLanguage) ? userLanguage : 'en';

    setLanguage(userLanguage);
  }, []);

  useEffect(() => {
    if (!open && window.recaptchaVerifier) {
      window.recaptchaVerifier.clear();
    }
  }, [open]);

  return (
    <div>
      <Modal
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <StyledModalBox>
          <Grid container justifyContent="flex-end">
            <Grid item xs={12} md={12}>
              <Grid container>
                <Grid item xs={11}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    textAlign={'left'}>
                    {t('heimdal_app.Profile.multi_factor_authentication')}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    aria-label="close"
                    sx={{
                      position: 'absolute',
                      right: '0.5rem',
                      top: '0.5rem',
                      color: 'text.secondary',
                    }}
                    onClick={() => {
                      handleClose();
                    }}>
                    <IconComponent
                      iconName={'close-outline'}
                      className={'icon'}
                    />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} paddingY={2}>
              <Typography
                id="modal-modal-description"
                variant="body2"
                textAlign={'left'}>
                {t('heimdal_app.Profile.enter_the_6_digit_code')}
              </Typography>
              <Grid
                item
                xs={12}
                sx={{
                  marginX: '2',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '1rem',
                }}>
                <ReactCodeInput
                  type="tel"
                  fields={6}
                  onChange={e => setCode(e)}
                  value={code}
                  inputStyle={styles.codeInput}
                />
              </Grid>
              <Grid item xs={12}>
                <LoadingButton
                  loading={isVerifying}
                  onClick={restartTimer}
                  size="small"
                  style={styles.loadingButton}
                  endIcon={<IconComponent iconName={'send-outline'} />}
                  disabled={!showButton}>
                  {props.showButton
                    ? t('heimdal_app.OTP.send_sms_again')
                    : `${t('heimdal_app.OTP.send_sms_again')} ${countdown}`}
                </LoadingButton>
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between" display={'flex'}>
              <Grid item xs={3}>
                <Typography
                  variant="subtitle2"
                  textAlign={'left'}
                  color={theme.palette.secondary.main}
                  onClick={() => {
                    handleClose();
                  }}
                  style={{ cursor: 'pointer', userSelect: 'none' }}>
                  {t('heimdal_app.Profile.cancel')}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  variant="subtitle2"
                  textAlign={'right'}
                  onClick={handleVerify}
                  style={{ cursor: 'pointer', userSelect: 'none' }}>
                  {t('heimdal_app.Profile.confirm')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </StyledModalBox>
      </Modal>
    </div>
  );
}
