import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';

import ReactCodeInput from 'react-code-input';
import LoadingButton from '@mui/lab/LoadingButton';

import { Grid, Typography } from '@mui/material';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';

export default function OPTCode(props) {
  const [key, setKey] = useState(0);
  const { t } = useTranslation();
  const { setIsLoading } = props;
  const code = props.code ? props.code : '';
  const resolver = props.resolver ? props.resolver : null;
  const PhoneNumber = props.PhoneNumber ? props.PhoneNumber : '';
  const isLargeScreen = useMediaQuery('(min-width: 1200px)'); // 1200px = lg

  const handleChangeCode = newValue => {
    props.setCaptchaCode(newValue);
    if (newValue.length === 6) {
      sendVerificationCode(newValue);
    }
  };

  useEffect(() => {
    async function asyncSendFirstSMS() {
      setIsLoading(false);
      await props.sendFirstSMS(props.resolver);
    }
    asyncSendFirstSMS();
  }, []);

  const sendVerificationCode = async newValue => {
    try {
      if (resolver != null && !props.showCode) {
        props.setUpRecaptha();
      } else {
        props.verifySMSCode(newValue);
      }
    } catch (error) {
      console.log('error sendig sms');
    }
  };

  const restartTimer = () => {
    props.setCaptchaCode('');
    props.setShowButton(false);
    props.setCountdown(15);
  };

  useEffect(() => {
    async function asyncLogInvalidation() {
      if (props.finishedSMS === true) {
        await props.logInValidation(props.resolver);
      }
    }
    asyncLogInvalidation();
  }, [props.finishedSMS]);

  useEffect(() => {
    if (code === '') {
      // A workaround to reset the input, since the component does not provide a way to do it
      setKey(key + 1);
    }
  }, [code]);

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={2}>
      {!isLargeScreen ? (
        <Grid item xs={12}>
          <img
            src="/images/logo_zippedi.png"
            alt="LOGO"
            width="100%"
            height="auto"
          />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Typography variant="h4" component="h4" gutterBottom align="center">
            {t('heimdal_app.Login.2-factor_account_authentication')}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography variant="body1" component="p" gutterBottom align="left">
          {t('heimdal_app.OTP.check_sms_1')} {PhoneNumber}{' '}
          {t('heimdal_app.OTP.check_sms_2')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <ReactCodeInput
            type="tel"
            fields={6}
            onChange={handleChangeCode}
            value={code}
            className="otp-input"
            key={key}
          />
        </div>
      </Grid>
      <Grid item xs={12}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}>
          {
            <LoadingButton
              loading={props.sendingOPT}
              onClick={restartTimer}
              size="small"
              sx={{ mt: 3, mb: 2 }}
              endIcon={<IconComponent iconName={'send-outline'} />}
              disabled={!props.showButton}
              id="recaptcha-button-resend"
              style={{ margin: 0 }}>
              {props.showButton
                ? t('heimdal_app.OTP.send_sms_again')
                : `${t('heimdal_app.OTP.send_sms_again')} ${props.countdown}`}
            </LoadingButton>
          }
          {!props.finishedSMS ? <div id="first-sms" /> : null}
        </div>
      </Grid>
    </Grid>
  );
}
