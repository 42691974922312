import { connect } from 'react-redux';
import MFA from '../../../components/profile/MFA';
import { logOut } from 'core/slices/session';
import { getProfile, revokeMFA } from 'core/slices/profile';
import { userProfile, isLoadingProfile } from 'core/selectors';

export default connect(
  state => ({
    userProfile: userProfile(state),
    isLoadingProfile: isLoadingProfile(state),
  }),
  { getProfile, revokeMFA, logOut },
)(MFA);
