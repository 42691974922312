import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';

import { Grid, Typography, Button, Box } from '@mui/material';
import { signInWithEmailAndPassword } from 'firebase/auth';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';

export default function PhoneNumberForm(props) {
  const PhoneNumber = props.PhoneNumber ? props.PhoneNumber : '';
  const isExt = props.isExt ? props.isExt : false;

  const handleChangePhone = newPhone => {
    props.phoneRef.current = newPhone;
    props.setPhoneNumber(newPhone);
  };

  const { t } = useTranslation();
  const isLargeScreen = useMediaQuery('(min-width: 1200px)'); // 1200px = lg

  useEffect(() => {
    async function asyncSetUpRecaptha() {
      props.setIsLoading(false);
      await props.setUpRecaptha();
    }
    asyncSetUpRecaptha();
  }, []);

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={2}>
      {!isLargeScreen ? (
        <Grid item xs={12}>
          <img
            src="/images/logo_zippedi.png"
            alt="LOGO"
            width="100%"
            height="auto"
          />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Typography variant="h4" component="h4" gutterBottom align="center">
            {t('heimdal_app.Login.2-factor_account_authentication')}
          </Typography>
        </Grid>
      )}

      <Grid item xs={12}>
        {isExt ? (
          <Typography align="left">
            {t('heimdal_app.Login.why_add_mfa')}
          </Typography>
        ) : (
          <Typography align="left">
            {t('heimdal_app.Login.require_add_mfa')}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12}>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <PhoneInput
            international
            countryCallingCodeEditable={false}
            defaultCountry="CL"
            displayInitialValueAsLocalNumber={true}
            value={PhoneNumber}
            onChange={handleChangePhone}
            signInWithEmailAndPassword={signInWithEmailAndPassword}
          />
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Button
          id={'send-info-button'}
          disabled={!isPossiblePhoneNumber(PhoneNumber)}
          variant="contained"
          type="submit"
          fullWidth
          sx={{ mb: 2 }}>
          {t('heimdal_app.Login.submit')}
        </Button>
      </Grid>
    </Grid>
  );
}
