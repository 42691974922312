import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Button, Typography, Paper, useTheme } from '@mui/material';
import Layout from '../navbar/Layout';
import Providers from '../../redux/containers/profile/Providers';
import ProfileForm from '../../redux/containers/profile/ProfileForm';
import MFA from '../../redux/containers/profile/MFA';
import MissingCredentialsModal from '../../redux/containers/profile/MissingCredentialsModal';
import {
  getAuth,
  getRedirectResult,
  getMultiFactorResolver,
  GoogleAuthProvider,
} from 'firebase/auth';

export default function Profile(props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { inputBackground = 'white', Inputcolor } = props;
  const lightBackground = inputBackground
    ? inputBackground
    : Object.values(theme.palette[inputBackground])[0];
  const styles = {
    paper: {
      width: '100%',
      height: '100%',
      background:
        theme.palette.type === 'light'
          ? lightBackground
          : theme.palette.paper.main,
      color: Inputcolor
        ? Inputcolor
        : theme.palette.type === 'light'
        ? theme.palette.primary.main + 'D9'
        : 'rgba(255, 255, 255, 0.87)',
    },
    typography: {
      color: theme.palette.type === 'light' ? 'black' : 'white',
    },
  };

  const {
    getProfile,
    userProfile,
    isLoadingProfile,
    isLoadingValidatePassword,
  } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [submit, setSubmit] = useState(false);
  const [canCreateAccount, setCanCreateAccount] = useState(false);
  const [canModifyProvider, setCanModifyProvider] = useState(false);
  const [resolver, setResolver] = useState(null);
  const [openOnProfile, setOpenOnProfile] = useState(false);
  const [openOnProvider, setOpenOnProvider] = useState(false);
  const [errorNew, setErrorNew] = useState(false);
  const [errorNewMsg, setErrorNewMsg] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [errorPasswordConfirmation, setErrorPasswordConfirmation] =
    useState(false);

  useEffect(() => {
    if (!userProfile) {
      getProfile();
    }
  }, [userProfile]);

  useEffect(() => {
    if (isLoadingProfile || isLoadingValidatePassword) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [isLoadingProfile, isLoadingValidatePassword]);

  useEffect(() => {
    if (!isLoading && userProfile) {
      const auth = getAuth();
      auth.tenantId = userProfile.tenantId;
      getRedirectResult(auth)
        .then(result => {
          if (result) {
            // No mfa registered
            if (['link', 'unlink'].includes(sessionStorage.getItem('action'))) {
              setCanModifyProvider(true);
            } else if (
              sessionStorage.getItem('action') === 'createEmailAccount'
            ) {
              setCanCreateAccount(true);
            } else if (sessionStorage.getItem('action') === 'googleLink') {
              try {
                const credential =
                  GoogleAuthProvider.credentialFromResult(result);
                if (!credential) {
                  console.log('could not get credential');
                  sessionStorage.removeItem('action');
                  sessionStorage.removeItem('provider');
                } else {
                  sessionStorage.removeItem('action');
                  sessionStorage.removeItem('provider');
                  window.location.reload();
                }
              } catch (error) {
                console.log(error);
                sessionStorage.removeItem('action');
                sessionStorage.removeItem('provider');
              }
            }
          }
        })
        .catch(error => {
          // MFA required
          if (error.code === 'auth/multi-factor-auth-required') {
            if (['link', 'unlink'].includes(sessionStorage.getItem('action'))) {
              setResolver(getMultiFactorResolver(auth, error));
              setOpenOnProvider(true);
            } else if (
              sessionStorage.getItem('action') === 'createEmailAccount'
            ) {
              setResolver(getMultiFactorResolver(auth, error));
              setOpenOnProfile(true);
            } else if (sessionStorage.getItem('action') === 'googleLink') {
              sessionStorage.removeItem('action');
              sessionStorage.removeItem('provider');
              window.location.reload();
              // We already verified the MFA, but google still needs to verify it although it is not needed and a simple reload will do the trick
              // If we still want to verify the MFA, firebase will throw an argument error. Seems like a bug in firebase when MFA is enabled.
            }
          }
        });
    }
  }, [userProfile, isLoading]);

  return (
    <Layout {...props}>
      <div id="recaptcha" />
      <Grid container paddingBottom={2}>
        <Grid container xs={12} alignItems="center">
          <Grid item xs={5} md={7}>
            <Typography variant="h6" sx={styles.typography}>
              {t('General.edit_profile')}
            </Typography>
          </Grid>
          <Grid
            item
            xs={7}
            md={5}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ textTransform: 'capitalize' }}
              onClick={() => {
                window.location.href = localStorage.getItem('redirect')
                  ? localStorage.getItem('redirect')
                  : '/';
              }}>
              <Typography variant={'inherit'}>
                {t('heimdal_app.Profile.return_to_previous_app')}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Paper sx={styles.paper}>
            <Grid container justifyContent="space-between">
              <ProfileForm
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                submit={submit}
                setSubmit={setSubmit}
                canCreateAccount={canCreateAccount}
                setResolver={setResolver}
                resolver={resolver}
                open={openOnProfile}
                setOpen={setOpenOnProfile}
                setErrorNew={setErrorNew}
                setErrorNewMsg={setErrorNewMsg}
                errorNew={errorNew}
                errorNewMsg={errorNewMsg}
                newPassword={newPassword}
                setNewPassword={setNewPassword}
                confirmNewPassword={confirmNewPassword}
                setConfirmNewPassword={setConfirmNewPassword}
                errorPasswordConfirmation={errorPasswordConfirmation}
                setErrorPasswordConfirmation={setErrorPasswordConfirmation}
                setCanCreateAccount={setCanCreateAccount}
                setOpenOnProfile={setOpenOnProfile}
              />
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} display={'flex'}>
          <Paper sx={styles.paper}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <MFA isLoading={isLoading} />
              </Grid>
              <Grid item xs={12}>
                <Providers
                  isLoading={isLoading}
                  canModifyProvider={canModifyProvider}
                  open={openOnProvider}
                  setOpen={setOpenOnProvider}
                  resolver={resolver}
                  setResolver={setResolver}
                  setErrorNew={setErrorNew}
                  setErrorNewMsg={setErrorNewMsg}
                  newPassword={newPassword}
                  confirmNewPassword={confirmNewPassword}
                  setErrorPasswordConfirmation={setErrorPasswordConfirmation}
                  setSubmit={setSubmit}
                  setIsLoading={setIsLoading}
                  setCanModifyProvider={setCanModifyProvider}
                  setOpenOnProvider={setOpenOnProvider}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      {!isLoading && <MissingCredentialsModal />}
    </Layout>
  );
}
