import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import './index.css';
import {
  Grid,
  Typography,
  TextField,
  Divider,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Link,
  useTheme,
} from '@mui/material';
import {
  getAuth,
  GoogleAuthProvider,
  signInWithRedirect,
  signInWithPopup,
  getMultiFactorResolver,
  setPersistence,
  browserLocalPersistence,
  SAMLAuthProvider,
} from 'firebase/auth';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';
import { LoadingButton } from '@mui/lab';

export default function LoginForm(props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const { tenantID, language, setIsLoading, isLoading, isLoadingRedirect, setIsLoadingRedirect, isLoadingSession } = props;
  const [showPassword, setShowPassword] = useState(false);

  const isLargeScreen = useMediaQuery('(min-width: 1200px)'); // 1200px = lg
  const url_forgot_pass = '/recover?mode=confirmEmail&tenantId=' + tenantID;

  function DateStrFormat(addYear = 0) {
    const today = new Date();
    const yyyy = today.getFullYear() + addYear;
    let mm = today.getMonth() + 1;
    let dd = today.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    const formattedToday = yyyy + '-' + mm + '-' + dd;
    return formattedToday;
  }

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const SignInWithProvider = async selectedProvider => {
    let provider;
    if (selectedProvider === 'google') {
      provider = new GoogleAuthProvider();
    } else if (selectedProvider === 'Demo-SAML') {
      provider = new SAMLAuthProvider('saml.demo-saml');
    }
    const auth = getAuth();
    setPersistence(auth, browserLocalPersistence);
    setIsLoading(!isLoading);
    auth.tenantId = tenantID;
    auth.languageCode = language;
    if (window.location.hostname !== 'localhost') {
      await signInWithRedirect(auth, provider);
    } else {
      setIsLoadingRedirect(true);
      await signInWithPopup(auth, provider)
        .then(result => {
          try {
            const user = result.user;
            if (result) {
              props.setStoredTdToken(result._tokenResponse.idToken);
              props.setStoredRefreshToken(result._tokenResponse.refreshToken);
            }
            const mfaList = user.reloadUserInfo.mfaInfo;
            let today = new Date();
            let isOnDate;
            const date_expiration = new Date(
              localStorage.getItem('date_expiration'),
            );
            const formattedToday = DateStrFormat();
            today = new Date(formattedToday);
            const diffInMs = date_expiration.getTime() - today.getTime();
            if (diffInMs > 0) {
              isOnDate = true;
            } else {
              isOnDate = false;
            }
            if (!mfaList) {
              if (
                !user.email.endsWith('@zippedi.com')
              )
                if (isOnDate === false) {
                  props.setShowPhoneNumberExt(true);
                } else {
                  const body = {
                    idToken: result._tokenResponse.idToken,
                    refreshToken: result._tokenResponse.refreshToken,
                  };
                  props.getSessionCookie(body);
                }
              else {
                setIsLoading(true);
                props.setShowPhoneNumber(true);
              }
            } else {
              const idToken = result._tokenResponse.idToken;
              const refreshToken = result._tokenResponse.refreshToken;
              const body = { idToken, refreshToken };
              props.getSessionCookie(body);
            }
          } catch {
            setIsLoading(false);
          }
        })
        .catch(error => {
          if (error.code === 'auth/user-disabled') {
            alert(t('heimdal_app.Login.user_disabled'));
          } else if (error.code === 'auth/multi-factor-auth-required') {
            setIsLoading(true);
            const resolver = getMultiFactorResolver(auth, error);
            props.setResolverAuth(resolver);
          } else {
            console.log(error.code);
          }
          setIsLoading(false);
        });
      setIsLoadingRedirect(false);
    }
  };

  const styles = {
    iconComponent: {
      color:
        theme.palette.type === 'dark' ? 'white' : theme.palette.primary.main,
    },
    iconButton: {
      ':hover': {
        backgroundColor:
          theme.palette.type === 'dark'
            ? `${theme.palette.primary.main}33`
            : `{theme.palette.grey[400]}33`,
      },
    },
  };

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={2}>
      {!isLargeScreen ? (
        <Grid item xs={12}>
          <img
            src="/images/logo_zippedi.png"
            alt="LOGO"
            width="100%"
            height="auto"
          />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Typography variant="h4" component="h4" gutterBottom align="center">
            {t('heimdal_app.Login.welcome')}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <TextField
          id="outlined-basic"
          value={props.email}
          label={t('heimdal_app.Login.email')}
          variant="outlined"
          fullWidth
          type="email"
          name="email"
          autoFocus
          onChange={props.handleChangeEmail}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel htmlFor="outlined-adornment-password">
            {t('heimdal_app.Login.password')}
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            name="password"
            value={props.password}
            label={t('heimdal_app.Login.password')}
            onChange={props.handleChangePassword}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  sx={styles.iconButton}
                  edge="end">
                  {showPassword ? (
                    <IconComponent
                      iconName={'eye-off-outline'}
                      className={'icon'}
                      style={styles.iconComponent}
                    />
                  ) : (
                    <IconComponent
                      iconName={'eye-outline'}
                      className={'icon'}
                      style={styles.iconComponent}
                    />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </Grid>
      <Grid container justifyContent="center" alignItems="center" spacing={2}>
        <Grid item xs={6}></Grid>
        <Grid item xs={6}>
          <Typography
            variant="body1"
            component="p"
            gutterBottom
            align="right"
            onClick={() => navigate(url_forgot_pass)}
            onMouseOver={e => {
              e.target.style.cursor = 'pointer';
            }}
            onMouseOut={e => {
              e.target.style.cursor = 'default';
            }}>
            <Link>{t('heimdal_app.Login.forgot_password')}</Link>
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <LoadingButton variant="contained" fullWidth loading={isLoadingRedirect || isLoadingSession} onClick={props.SignInWithEmail} disabled={isLoadingRedirect || isLoadingSession}>
          {t('heimdal_app.Login.sign_in')}
        </LoadingButton>
      </Grid>
      <Grid item xs={12}>
        <Divider> {t('heimdal_app.Login.email_option_label')} </Divider>
      </Grid>
      <Grid item xs={12}>
        <LoadingButton
          variant="outlined"
          loading={isLoadingRedirect || isLoadingSession}
          disabled={isLoadingRedirect || isLoadingSession}
          startIcon={
            <img
              src="images/logo_google.png"
              width={'25px'}
              height={'25px'}
              alt="Google"
            />
          }
          onClick={() => SignInWithProvider('google')}
          fullWidth>
          {t('heimdal_app.Login.sign_in_with_zippedi')}
        </LoadingButton>
      </Grid>
      {/* <Grid
        container
        justifyContent="left"
        alignItems="left"
        spacing={2}
        marginTop={'1em'}>
        <Grid item marginLeft={'1em'}>
          <IconButton onClick={() => SignInWithProvider("google")}>
            <Avatar
              alt="Google"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/2008px-Google_%22G%22_Logo.svg.png"
              sx={{ width: '50px', height: '50px' }}
            />
          </IconButton>
        </Grid>
        <Grid item marginLeft={'1em'}>
          <IconButton onClick={() => SignInWithProvider("Demo-SAML")}>
            <Avatar
              alt="SAML"
              src="https://images.peerspot.com/image/upload/c_scale,f_auto,q_auto,w_200/yswbqertf9cj65ta5ybi24j3pw48.jpg"
              sx={{ width: '50px', height: '50px' }}
            />
          </IconButton>
        </Grid>
      </Grid>*/}
    </Grid>
  );
}
